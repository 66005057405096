import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AccountingService } from "src/app/services/accounting/accounting.service";
import { OnBoardingService } from "src/app/services/onboarding/onboarding.service";

@Injectable(
    {
        providedIn: 'root'
    }
)
export class OnboardingResolver {
    constructor(public onBoardingService: OnBoardingService) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return this.onBoardingService.getOnBoardingUserList(1, 10, '').toPromise().then(data => data.data)
    }
}