import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LoginAuthGuard  {

    constructor(
        public router: Router
    ) { }

    canActivate(): boolean {
        if (localStorage.getItem('loggedInUser')) {
            this.router.navigate(['dashboard']);
            return false;
        } else {
            return true;
        }
    }

}
