import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthenticateService } from "src/app/services/authenticateService/authenticate.service";
import { ContactServiceService } from "src/app/services/contacts/contact-service.service";
import { TranscodingService } from "src/app/services/transcoding/transcoding.service";

@Injectable(
    {
        providedIn: 'root'
    }
)
export class ContactListResolver {
    constructor(
        public contactService: ContactServiceService,
        public authenticateService: AuthenticateService
    ) {
    
    } 
    orderObj : any
    page: number = 1
    rows: number = 10
    queryParamsValue : any
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        // console.log(route.queryParams)
        this.queryParamsValue = route.queryParams.page ? route.queryParams : ''
        if (this.queryParamsValue) {
            this.page = route.queryParams.page
        }
        return Promise.all([
            this.contactService.getContactList( this.page, this.rows , '', '', '', true, '','').toPromise().then(data => data),
            this.authenticateService.getCountryList().toPromise().then(data => data),
        ]);
    }
}