import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import * as moment from 'moment-timezone';;
import { AppsService } from "src/app/services/apps/apps-service";
import { SubscriptionServiceService } from "src/app/services/subscription/subscription-service.service";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionPlanResolver {
    constructor(
        public appsService: AppsService,
        public subscriptionService: SubscriptionServiceService
    ) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {


          if(route.queryParams.dashboard && route.queryParams.date === 'year' ){
              console.log('test--', route.queryParams.dashboard)
       
              var date = new Date(), y = date.getFullYear(), m = date.getMonth();
              var firstMonth = new Date("1/1/" + y);
              var lastDays = new Date(y, m + 1, 0);
              const dateObj1 = new Date()
              var firstDate = moment(firstMonth).format('YYYY-MM-DD');
              var lastDate = moment(dateObj1).format('YYYY-MM-DD');
              const startDate = firstDate
              const endDate = lastDate
          }
          else {
              const loginUserDetail = localStorage.getItem("loggedInUser");
              const orgId = loginUserDetail ? JSON.parse(loginUserDetail).userDetails.organization.id : "";
              var date = new Date(), y = date.getFullYear(), m = date.getMonth();
              var firstDays = new Date(y, m, 1);
              var lastDays = new Date(y, m + 1, 0);
              var firsts = moment(firstDays).format('YYYY-MM-DD')
              var lasts = moment(lastDays).format('YYYY-MM-DD')
              const startDate = firsts
              const endDate = lasts
              return Promise.all([
                  this.appsService.getCheckStripeKeys().toPromise().then(data => data),
                  this.subscriptionService.getSubscriptionDataList(1, 10, '', endDate, startDate, true, '').toPromise().then(data => data),
                  this.subscriptionService.getSubscriptionList(orgId).toPromise().then(data => data),
              ]);
     }
        
    }
}