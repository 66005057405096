import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { CustomerService } from "src/app/services/customer/customer.service";
import { RequestService } from "src/app/services/request/request.service";
import { SocialMediaServicesService } from "src/app/services/social/social-media-services.service";
import { TranscodingService } from "src/app/services/transcoding/transcoding.service";

@Injectable({
    providedIn: 'root'
})
export class SocialMediaResolver {
    constructor(
        public socialMediaService: SocialMediaServicesService,
    ) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return Promise.all([
            this.socialMediaService.getAccountStatistics(1, 5).toPromise().then(data => data),
            this.socialMediaService.getPageDataForConnectedSocialMedia().toPromise().then(data => data),
            this.socialMediaService.getRecentPosts().toPromise().then(data => data),
            this.socialMediaService.getScheduledPosts(1, 6).toPromise().then(data => data),
        ])
        .catch(function(err) {
            //   console.log(err.message); // some coding error in handling happened
            });;
    }
}