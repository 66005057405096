import { Injectable } from '@angular/core';
import { appConfig } from './../../app.config';
import { ResourceService } from '../resource/resource.service';
import { forkJoin, throwError as observableThrowError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RequestService {



constructor(public resourceService: ResourceService) { }


getRequestcustomers(page, size,requestType,search) {

  const filter = (search && requestType) ? `&searchKey=${search}&requestType=${requestType}` : ((requestType) ? `&requestType=${requestType}` : ((search) ? `&searchKey=${search}`: ((search && requestType) ? `&searchKey=${search}&requestType=${requestType}` : ((requestType) ? `&requestType=${requestType}` : ((search) ? `&searchKey=${search}`: '' ) ) ) ))
  const getrequestorganization = `${appConfig.getrequestorganization}?page=${page}&size=${size}${filter}`;
  return this.resourceService.getData(getrequestorganization);
}


getRequestById(requestId) {
  const getrequestorganizationbyId = `${appConfig.viewrequestorganization}?requestId=${requestId}`;
  return this.resourceService.getData(getrequestorganizationbyId);
}
// new
statussent(data) {
  const updateStatus = `${appConfig.updateRequeststatus}`;
  return this.resourceService.putData(updateStatus, data);
}

errorHandler(error: HttpErrorResponse) {
  return observableThrowError(error || 'Server Error');
}

}
