import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CustomerService } from "src/app/services/customer/customer.service";
import { LazyLoadEvent, MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class CustomerListResolver {
    constructor(public _customerService: CustomerService) {

    }
    page: number = 1
    rows: number
    queryParamsValue: any
    first = 0
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        if (route.queryParams.page && route.queryParams.row) {
            this.page = route.queryParams.page
            this.rows = route.queryParams.row
        }
        else if(route.queryParams.page){
            this.page = route.queryParams.page
            this.rows = route.queryParams.row ? route.queryParams.row : 50
        }
        else{
            this.page = 1
            this.rows = 50
        }
        return this._customerService.getCustomers(this.page,this.rows ,'', '', route.queryParams.active ? route.queryParams.active : true  , route.queryParams.status === 'all' ? ''  : route.queryParams.status ? route.queryParams.status : 'PAID' , '').toPromise().then(data => data.data)


    }
}