import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import * as moment from 'moment-timezone';;
import { ContactusService } from "src/app/services/contactus/contactus.service";
import { SettingsServiceService } from "src/app/services/settings/settings-service.service";

@Injectable({
    providedIn: 'root'
})
export class QueryListResolver {
    constructor(public contactusService: ContactusService) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDays = new Date(y, m, 1);
        var lastDays = new Date(y, m + 1, 0);
        var firsts = moment(firstDays).format('YYYY-MM-DD')
        var lasts = moment(lastDays).format('YYYY-MM-DD')
        const startDate = firsts
        const endDate = lasts
        const filterValue = 'month'
        const selectedFilter = 'month'

        return this.contactusService.getqueryMessages(1, 10, '', true, startDate, endDate).toPromise().then(data => data.data)
    }
}