import { SafePipe } from './../../utilities/pipes/safe.pipe';
import { WhiteSpaceValidatorDirective } from './../../utilities/directives/white-space.directive';
import { AppInterceptor } from './../../interceptors/interceptor';
import { ToastModule } from 'primeng/toast';
import { ArtworkComponent } from './../../components/shared/artwork/artwork.component';
import { SvgIconsDirective } from './../../utilities/directives/svg-icon-directive';
import { InitialComponent } from './../../components/shared/initial/initial.component';
import { SidebarComponent } from './../../components/shared/sidebar/sidebar.component';
import { HeaderComponent } from './../../components/shared/header/header.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChartModule } from 'primeng/chart';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
// import { ImageCropperModule } from 'ngx-image-cropper';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DialogModule } from 'primeng/dialog';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MobileAppListComponent } from 'src/app/components/shared/panels/mobile-app-list/mobile-app-list.component';
import { WebListComponent } from 'src/app/components/shared/panels/web-list/web-list.component';
import { TVListComponent } from 'src/app/components/shared/panels/tv-list/tv-list.component';
import { AddToListSidePopUpComponent } from 'src/app/components/library/add-to-list-side-pop-up/add-to-list-side-pop-up.component';
import { AddToListComponent } from 'src/app/components/shared/panels/add-to-list/add-to-list.component';
import { FeaturedPopUpComponent } from 'src/app/components/shared/panels/featured-pop-up/featured-pop-up.component';
import { AddToFolderSidePopUpComponent } from 'src/app/components/shared/panels/add-to-folder-side-pop-up/add-to-folder-side-pop-up.component';
import { ChangeEventpanelComponent } from 'src/app/components/shared/panels/change-eventpanel/change-eventpanel.component';
import { SocialLeadEditComponent } from 'src/app/components/shared/panels/social-lead-edit/social-lead-edit.component';
import { PaginatorModule } from 'primeng/paginator';
import { ChangemediaseriesPopupComponent } from 'src/app/components/media/changemediaseries-popup/changemediaseries-popup.component';
import { EmbedsSidePopUpComponent } from 'src/app/components/shared/panels/embeds-side-pop-up/embeds-side-pop-up.component';
import { MostRecentMediaSidepanelComponent } from 'src/app/components/shared/panels/most-recent-media-sidepanel/most-recent-media-sidepanel.component';
import { EventsEmbedSidePanelComponent } from 'src/app/components/shared/panels/events-embed-side-panel/events-embed-side-panel.component';
import { MobileEmbedSidePanelComponent } from 'src/app/components/shared/panels/mobile-embed-side-panel/mobile-embed-side-panel.component';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StaticHeaderPanelComponent } from 'src/app/components/shared/panels/static-header-panel/static-header-panel.component';
// import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MobileAppsComponent } from 'src/app/components/shared/mobile-apps/mobile-apps.component';
import { CarouselModule } from 'primeng/carousel';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { PlyrModule } from 'ngx-plyr';
import { NgxScrollPositionRestorationModule } from 'ngx-scroll-position-restoration';
import { CalendarSidePanelComponent } from 'src/app/components/shared/panels/calendar-side-panel/calendar-side-panel.component';
import { EbookseriesSidePanelComponent } from 'src/app/components/shared/panels/ebookseries-side-panel/ebookseries-side-panel.component';
import { VideoPlayerComponent } from 'src/app/components/shared/video-player/video-player.component';
import { AddChannelPanelComponent } from 'src/app/components/shared/panels/add-channel-panel/add-channel-panel.component';
import { NetworkseriesSidePanelComponent } from 'src/app/components/shared/panels/networkseries-side-panel/networkseries-side-panel.component';
import { ChangeChannelSidePanelComponent } from 'src/app/components/shared/panels/change-channel-side-panel/change-channel-side-panel.component';
import { CommunicationComponent } from 'src/app/components/communication/communication.component';
import { ScrollUpDirective } from 'src/app/components/communication/scroll-up.directive';
import { StorageUsageComponent } from 'src/app/components/shared/storage-usage/storage-usage.component';
import { ShowPerModalComponent } from 'src/app/components/shared/show-per-modal/show-per-modal.component';
import { InputSwitchModule } from 'primeng/inputswitch';
// import {GMapModule} from 'primeng/gmap';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    ChartModule,
    TableModule,
    MultiSelectModule,
    CalendarModule,
    // ImageCropperModule,
    TooltipModule,
    ToastModule,
    DialogModule,
    LazyLoadImageModule,
    PaginatorModule,
    // NgxPhotoEditorModule,
    // MatSidenavModule,
    // NgbModule,
    // DragDropModule,
    // NgImageFullscreenViewModule,
    // NgxMaterialTimepickerModule,
    ToggleButtonModule,
    CarouselModule,
    // PlyrModule,
    InputSwitchModule
    // GMapModule
  ],
  declarations: [
    // ImageCropperModule
    // HeaderComponent,
    // SidebarComponent,
    // InitialComponent,
    // // SvgIconsDirective,
    // ArtworkComponent,
    // WhiteSpaceValidatorDirective,
    // // SafePipe,
    // MobileAppListComponent,
    // WebListComponent,
    // TVListComponent,
    // // AddToListComponent,
    // FeaturedPopUpComponent,
    // AddToFolderSidePopUpComponent,
    // ChangeEventpanelComponent,
    // SocialLeadEditComponent,
    // ChangemediaseriesPopupComponent,
    // EmbedsSidePopUpComponent,
    // MostRecentMediaSidepanelComponent,
    // EventsEmbedSidePanelComponent,
    // MobileEmbedSidePanelComponent,
    // StaticHeaderPanelComponent,
    // MobileAppsComponent,
    // CalendarSidePanelComponent,
    // EbookseriesSidePanelComponent,
    // VideoPlayerComponent,
    // CommunicationComponent,
    // ScrollUpDirective,
    // AddChannelPanelComponent,
    // NetworkseriesSidePanelComponent,
    // // ChangeChannelSidePanelComponent,
    // StorageUsageComponent,
    // ShowPerModalComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
  ],
  exports: [
    // PlyrModule
    // RouterModule,
    // CommonModule,
    // HeaderComponent,
    // // SidebarComponent,
    // // SvgIconsDirective,
    // ChartModule,
    // TableModule,
    // MultiSelectModule,
    // CalendarModule,
    // // ArtworkComponent,
    // ImageCropperModule,
    // // NgxPhotoEditorModule,
    // TooltipModule,
    // ToastModule,
    // FormsModule,
    // DialogModule,
    // WhiteSpaceValidatorDirective,
    // // SafePipe,
    // MobileAppListComponent,
    // WebListComponent,
    // TVListComponent,
    // // AddToListComponent,
    // AddToFolderSidePopUpComponent,
    // ChangeEventpanelComponent,
    // SocialLeadEditComponent,
    // PaginatorModule,
    // ChangemediaseriesPopupComponent,
    // EmbedsSidePopUpComponent,
    // MostRecentMediaSidepanelComponent,
    // EventsEmbedSidePanelComponent,
    // MobileEmbedSidePanelComponent,
    // StaticHeaderPanelComponent,
    // NgImageFullscreenViewModule,
    // // NgxMaterialTimepickerModule,
    // MobileAppsComponent,
    // NgxScrollPositionRestorationModule,
    // CalendarSidePanelComponent,
    // EbookseriesSidePanelComponent,
    // VideoPlayerComponent,
    // NetworkseriesSidePanelComponent,
    // ChangeChannelSidePanelComponent,
    // StorageUsageComponent,
    // ShowPerModalComponent,
    // InputSwitchModule
    // GMapModule
  ],
})
export class SharedModule { }
