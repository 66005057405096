import { appConfig } from './../../app.config';
import { Injectable } from '@angular/core';
import { ResourceService } from '../resource/resource.service';

@Injectable({
  providedIn: 'root'
})

export class OnBoardingService {

  constructor(public resourceService: ResourceService) { }

  getSecurityTemplate() {
    const getSecurityGroupUrl = `${appConfig.securityGroupList}`;
    return this.resourceService.getData(getSecurityGroupUrl);
  }

  updateUser(formData, userId) {
    const updateUserUrl = userId ? `${appConfig.updateUser}/${userId}` : appConfig.updateUser;
    if (userId) {
      return this.resourceService.putData(updateUserUrl, formData);
    } else {
      return this.resourceService.postData(updateUserUrl, formData);
    }
  }

  getOnBoardingUserList(page, size, search?) {
    const getUserUrl = search ? `${appConfig.updateUser}/onboardingUsers?page=${page}&size=${size}&searchKey=${search}` :
      `${appConfig.updateUser}/onboardingUsers?page=${page}&size=${size}`;
    return this.resourceService.getData(getUserUrl);
  }

  getOnBoardingUser(userId) {
    const getOnBoardingUser = `${appConfig.updateUser}/${userId}`;
    return this.resourceService.getData(getOnBoardingUser);
  }

  deleteOnBoardingUser(userId) {
    const deleteOnBoardingUser = `${appConfig.updateUser}/${userId}`;
    return this.resourceService.deleteData(deleteOnBoardingUser);
  }

}

