import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { CustomerService } from "src/app/services/customer/customer.service";
import { SettingsServiceService } from "src/app/services/settings/settings-service.service";

@Injectable({
    providedIn: 'root'
})
export class MailsResolver {
    constructor(
        public settingsService: SettingsServiceService,
        public customerService: CustomerService
    ) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return Promise.all([
            this.customerService.getTemplate().toPromise().then(data => data),
            this.settingsService.getGeneralSettingList().toPromise().then(data => data),
        ]);
    }
}