import { Injectable } from '@angular/core';
import { appConfig } from './../../app.config';
import { ResourceService } from '../resource/resource.service';

@Injectable({
  providedIn: 'root'
})
export class TranscodingService {

  constructor(public resourceService: ResourceService) { }

  getTranscodingData(page, size, searchKey , transCodingStatus, selectedOrganizationId,flavorType) {
    const flavor = flavorType ? `flavourType=${flavorType}` : ''  
    const organizationId = selectedOrganizationId ? `organizationId=${selectedOrganizationId}` : ''
    const search = searchKey ? `&searchKey=${searchKey}` : ''
    const filter = (transCodingStatus) ? `&transcodingStatus=${transCodingStatus}` : ''
    if(flavor){
      if(organizationId){
        var videoTranscodingUrl = `${appConfig.videoTranscoding}?${flavor}&${organizationId}&page=${page}${search}&size=${size}${filter}`;
      }
      else{
        var videoTranscodingUrl = `${appConfig.videoTranscoding}?${flavor}&page=${page}${search}&size=${size}${filter}`;
      }
    }
    else{
      var videoTranscodingUrl = `${appConfig.videoTranscoding}?${organizationId}&page=${page}${search}&size=${size}${filter}`;
    }
    return this.resourceService.getData(videoTranscodingUrl);
  }


  deleteTranscodingItem(userId) {
    const deleteTranscoding = `${appConfig.videoTranscoding}/${userId}`;
    return this.resourceService.deleteData(deleteTranscoding);
  }

  updateTranscodingItem(userId, transCodingStatus){
    const updateTranscoding = `${appConfig.videoTranscoding}/${userId}?transcodingStatus=${transCodingStatus}`;
    return this.resourceService.putData(updateTranscoding);
  }
  
}
