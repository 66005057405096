import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SIGXFSZ } from 'constants';
import {appConfig} from './../../app.config';
import {ResourceService} from './../../services/resource/resource.service';


@Injectable({
  providedIn: 'root'
})
export class GivingServiceService {

  constructor(
    public resourceService : ResourceService,
    public http:HttpClient
  ) { }

  /* get Giving */

 

  /* getGiving By Id */

  getGivingById(id){
    const getGivingByIdURL = `${appConfig.giving}/${id}`
    return this.resourceService.getData(getGivingByIdURL)
    
  }

  getGiving(page,size,search,endDate,startDate,isDefault,isSelected){

    // const filter = (date && search) ? `&state=${date}&searchkey=${search}` : (date ? `&date=${date}` :
    //   (search ? `&searchkey=${search}` : ''));

    // const getGivingURL = `${appConfig.giving}/?page=${page}&size=${size}${filter}`
    // return this.resourceService.getData(getGivingURL)

    const searchData = search ? `&searchkey=${search}` : ''
    const end = endDate ? `endDate=${endDate}&` : ''
    const start = startDate ? `&startDate=${startDate}` : ''

    const selected = isSelected ? `&isSelected=${isSelected}` : ''
    const filter = search ? `&searchKey=${search}` : ''
    const DefaultData = (endDate ? `&isDefault=${isDefault}`: `?isDefault=${isDefault}`)
    const getGivingURL = `${appConfig.giving}?${end}${DefaultData}${selected}&page=${page}${searchData}&size=${size}${start}`
    return this.resourceService.getData(getGivingURL)


  }
  //giving generate statement all data
  getGivingStatementData(page,size,endDate,startDate,isSelected){
    const selected = isSelected ? `&isSelected=${isSelected}` : ''
    const end = endDate ? `endDate=${endDate}` : ''
    const start = startDate ? `&startDate=${startDate}` : ''
    // const searchData = searchkey ? `&searchkey=${searchkey}` : ''
    const getGivingByIdURL = `${appConfig.giving}/getAllGivingUsers?${end}${selected}&page=${page}&size=${size}${start}`
    return this.resourceService.getData(getGivingByIdURL)
    
  }
  
  GivingReceipt(data,fileName,isDownload,isGenerate) {
    const statementName = fileName ? `?fileName=${fileName}` : ''
    const download = (fileName ? `&isDownload=${isDownload}` : `?isDownload=${isDownload}`)
    const generate = (isGenerate ? `&isGenerate=${isGenerate}` : `&isGenerate=${isGenerate}`)
    const MessageStatus = `${appConfig. giving}/GivingReceiptByEmail${statementName}${download}${generate}`
    return this.http.put(MessageStatus,data,{responseType: 'arraybuffer'})
  }
  // update giving statement data
  UpdateGivingStatementData(data,givingStatementId) {
    const MessageStatus = `${appConfig. givingStatement}/${givingStatementId}`
    return this.resourceService.putData(MessageStatus,{data,responseType: 'arraybuffer'})
  }
//end date start date
  getGivingStatementListData(page,size,searchkey,endDate,startDate,isSelected){
    const selected = isSelected ? `&isSelected=${isSelected}` : ''
    // const pages = (endDate ? `&isSelected=${isSelected}` : `?isSelected=${isSelected}`)
    const end = endDate ? `endDate=${endDate}` : ''
    const start = startDate ? `&startDate=${startDate}` : ''
    const searchData = searchkey ? `&searchkey=${searchkey}` : ''
    const getGivingByIdURL = `${appConfig.givingStatement}?${end}${selected}&page=${page}${searchData}&size=${size}${start}`
    return this.resourceService.getData(getGivingByIdURL)
  }
  // delete statement list
  deleteStatementList(userId) {
    const deleteList = `${appConfig.givingStatement}/${userId}`;
    return this.resourceService.deleteData(deleteList);
  }
  // getGivingStatementById
  getGivingStatementById(id,page,size){
    const pages = page ? `?page=${page}` : ''
    const rows = size ? `&size=${size}` : ''
    const getGivingByIdURL = `${appConfig.givingStatement}/${id}${pages}${rows}`
    return this.resourceService.getData(getGivingByIdURL)
  }

  
// GIvingCsvDownload

  // downloadCsv(){
  //   const download = `${appConfig.csvDownload}/GivingCsvDownload`
  //   return this.http.get(download,{responseType: 'blob'})
  // }
  downloadCsv(downloadType,searchkey){
    const searchData = searchkey ? `&searchkey=${searchkey}` : ''
    const download = `${appConfig.csvDownload}/GivingCsvDownload?downloadType=${downloadType}${searchData}`
    // const headers = new Headers({ 'Content-Type': 'application/json', 
    // responseType : 'blob'});
    // return this.http.get(download, { headers : headers})
    return this.http.get(download,{responseType : 'blob'})
  }

  deleteStatementInBulk(id) {
    const deleteMediaUrl = `${appConfig.givingStatement}/delete?${id}`;
    return this.resourceService.deleteData(deleteMediaUrl);
  }
}
