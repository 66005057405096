import { Injectable } from '@angular/core';
import { appConfig } from 'src/app/app.config';
import { ResourceService } from '../resource/resource.service';

@Injectable({
  providedIn: 'root'
})
export class PodcastService {

  constructor(public resourceService: ResourceService) { }


  updatePodcast(id, data, type){
    const createPodcastUrl = `${appConfig.podcast}/${id}`;
      return this.resourceService.putData(createPodcastUrl, data);
  }

  createPodcast(data) {

     const createPodcastUrl = `${appConfig.podcast}/createPodcast`;
    return this.resourceService.postData(createPodcastUrl, data);
  }

  getPodcastList(page,size,search) {
    const filter = search ? `&searchkey=${search}` : ''
    const getPodcastList = `${appConfig.podcast}/createPodcast?page=${page}${filter}&size=${size}`;
    return this.resourceService.getData(getPodcastList);
  }
  // selectAll podcast List
  getSelectAllPodcastList(page,size,search,isSelected) {
    const selected = isSelected ? `?isSelected=${isSelected}` : ''
    const pages = (isSelected ? `&page=${page}` : `?page=${page}`)
    const filter = search ? `&searchkey=${search}` : ''
    const getPodcastList = `${appConfig.podcast}/createPodcast${selected}${pages}${filter}&size=${size}`;
    return this.resourceService.getData(getPodcastList);
  }

  getPodcastById(id){
    const getPodcastById = `${appConfig.podcast}/${id}`;
    return this.resourceService.getData(getPodcastById);
  }

  getAlbumById(id){
    const getPodcastById = `${appConfig.podcast}/${id}`;
    return this.resourceService.getData(getPodcastById);
  }

  getCategoryList(){
    const getCategoryList = `${appConfig.category}/createCategory`;
    return this.resourceService.getData(getCategoryList);
  }
  // podcast single delete
  deletePodcastItem(id){
    const deletePodcastItem = `${appConfig.podcast}/delete?ids=${id}`;
    return this.resourceService.deleteData(deletePodcastItem);
  }
  // podcast bulk delete 
  deleteBulkPodcast(id){
    const deletePodcastItem = `${appConfig.podcast}/delete?${id}`;
    return this.resourceService.deleteData(deletePodcastItem);
  }

}
