import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AccountingService } from "src/app/services/accounting/accounting.service";

@Injectable({
    providedIn: 'root'
})
export class ExpenseResolver {
    constructor(public accountingService: AccountingService) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return this.accountingService.getExpenses(1, 10, '', '', '').toPromise().then(data => data.data)
    }
}