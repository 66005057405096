import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AppsService } from "src/app/services/apps/apps-service";
import { WebServiceService } from "src/app/services/web/web-service.service";

@Injectable({
    providedIn: 'root'
})
export class WebAppResolver {
    constructor(
        public appsService: AppsService,
        public webService: WebServiceService
    ) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return Promise.all([
            this.appsService.getCheckStripeKeys().toPromise().then(data => data),
            this.webService.getWeb().toPromise().then(data => data),
        ]);
    }
}