// import { settings } from 'cluster';
import { environment } from '../environments/environment';

export const appConfig = {

  // manual height width of artwork

  // square
  squareWidth: 200,
  squareHeight: 200,

  // wide
  wideWidth: 160,
  wideHeight: 90,

  // artwork wide
  artworkWideWidth: 480,
  artworkWideHeight: 270,

  // banner
  bannerWidth: 415,
  bannerHeight: 150,


  // A4

  ebookWidth: 49,
  ebookHeight: 77,

  baseURL: environment.basePath,

  logIn: environment.basePath + environment.authService + 'user/login',
  tokenValidate: environment.basePath + environment.authService + 'tokenValidate',
  logOut: environment.basePath + environment.authService + 'user/logout',
  switch: environment.basePath + environment.authService + 'user/switchUser',
  signUp: environment.basePath + environment.adminService + 'signUp',
  reset: environment.basePath + environment.adminService + 'user/forgetPassword',
  forgetPassword: environment.basePath + environment.adminService + 'user/forgetPasswordLink',
  checkTokenValidate: environment.basePath + environment.adminService + 'user/tokenExpired',

  // try
  getrequestorganization: environment.basePath + environment.adminService + 'changedRequest/changedRequestsList',
  viewrequestorganization: environment.basePath + environment.adminService + 'changedRequest/viewdRequestsList',

  //  -----***----
  // requestpagePutApi's
  updateRequeststatus: environment.basePath + environment.adminService + 'changedRequest/updateRequestStatus',

  //

  /* OnBoarding Urls */
  updateUser: environment.basePath + environment.adminService + 'user',

  /* Organization Urls */
  countryList: environment.basePath + environment.adminService + 'organization/getAllCountryList',
  securityGroupList: environment.basePath + environment.adminService + 'securityGroup',
  updateOrganization: environment.basePath + environment.adminService + 'organization',

  /* Accounting Urls */
  updateExpense: environment.basePath + environment.adminService + 'account/expense',
  updateRevenue: environment.basePath + environment.adminService + 'account/revenue',
  getExpenseList: environment.basePath + environment.adminService + 'account/expense/getList',
  getRevenueList: environment.basePath + environment.adminService + 'account/revenue/getList',
  getRevenueListBoard: environment.basePath + environment.adminService + 'account/revenue/getListBoard',

  /* Security Urls */
  getSecurityGroup: environment.basePath + environment.adminService + 'securityGroup/users',
  updatePermission: environment.basePath + environment.adminService + 'permission',
  updateSecurityGroup: environment.basePath + environment.adminService + 'securityGroup',

  /* Upload Urls */
  upload: environment.basePath + environment.imageService + '/upload',
  updateStock: environment.basePath + environment.adminService + 'uploadStock',

  /* Template Urls */
  template: environment.basePath + environment.adminService + 'content',

  /* Media Urls */
  mediaItem: environment.basePath + environment.adminService + 'mediaItem',
  mediaSeries: environment.basePath + environment.adminService + 'mediaseries',
  getAnalyticDetail: environment.basePath + environment.adminService  + "viewerAnalytics/individualViewerAnalytics",
  getCumulativeTask: environment.basePath + environment.adminService  + "viewerAnalytics/getCumulativeViewerCount",
  getViewerCountByDateRange: environment.basePath + environment.adminService  + "viewerAnalytics/viewerCountByRange",
  //  Podcast Urls

  // ebook

  getcollectionData: environment.basePath + environment.adminService + 'ebookReader',
  getEbook: environment.basePath + environment.adminService + 'eBook',


  //
  podcast: environment.basePath + environment.adminService + 'podcast',

  // Category Urls
  category: environment.basePath + environment.adminService + 'category',

  /* Folder Urls */
  catalog: environment.basePath + environment.adminService + 'catalog',

  /* Link Urls */
  link: environment.basePath + environment.adminService + 'link',

  /* RSS Feed urls */
  rssFeed: environment.basePath + environment.adminService + 'rssFeed',

  //  Music Url's
  musicList: environment.basePath + environment.adminService + 'music',
  album: environment.basePath + environment.adminService + 'album',

  /* Design List */
  listDesign: environment.basePath + environment.adminService + 'listDesign',

  // Tabs URL's
  tabs: environment.basePath + environment.adminService + 'tab',

  // bibleReadingPlan URL's
  bibleReadingPlan: environment.basePath + environment.adminService + 'bibleReadingPlan',

  // settings app store info url

  appStoreInfo: environment.basePath + environment.adminService + 'appStoreInfo',


  // settings tax info url

  taxInfo: environment.basePath + environment.adminService + 'taxInfo',

  // settings developers account

  developerAccount: environment.basePath + environment.adminService + 'developerAccount',

  // general settings

  organizationSetting: environment.basePath + environment.adminService + 'organizationSetting',

  /* upload promotional image in organization */

  organization: environment.basePath + environment.adminService + 'organization',

  // branding

  branding: environment.basePath + environment.adminService + 'branding',

  // setting user

  user: environment.basePath + environment.adminService + 'user',

  //    setting subscription
  usersubScription: environment.basePath + environment.adminService + 'usersubScription',

  // calendar
  calendar: environment.basePath + environment.adminService + 'calendar',

  // calnder Event
  calendarEvent: environment.basePath + environment.adminService + 'calendarEvent',
  // event
  event: environment.basePath + environment.adminService + 'event',

  //events

  events: environment.basePath + environment.adminService + 'events',
  followingevents: environment.basePath + environment.adminService + 'followingEvents',

  // eventregistration get by id also
  registerdUsers: environment.basePath + environment.adminService + 'event',

  //updateEvent

  updateEvent: environment.basePath + environment.adminService + 'updateEvents',

  // contact
  contact: environment.basePath + environment.adminService + 'contact',

  // dashboard
  dashboard: environment.basePath + environment.adminService + 'dashboard',



  // Notification
  notification: environment.basePath + environment.adminService + 'notification',

  pushNotification: environment.basePath + environment.adminService + 'pushNotification',

  imageURL: environment.basePath + environment.imageService + '/upload/load/',

  notificationInfo: environment.basePath + environment.adminService + 'notificationInfo',

  // giving

  giving: environment.basePath + environment.adminService + 'giving',
  givingStatement: environment.basePath + environment.adminService + 'givingStatement',

  // subscription

  subscription: environment.basePath + environment.adminService + 'subscription',



  // QueryMessage

  queryMessage: environment.basePath + environment.adminService + 'contactUs',






  // media Play

  mediaPlay: environment.basePath + environment.adminService + 'mediaPlay',

  // appInfo

  appInfo: environment.basePath + environment.adminService + 'appInfo',

  // addSocialMedia

  socialMedia: environment.basePath + environment.adminService + 'socialMedia',

  // oauth2Login Post Message on Social Media

  oauth2Login: environment.basePath + environment.adminService + 'oauth2Login',

  //analytics

  analytics: environment.basePath + environment.adminService + 'analytics',

  //memory storage

  memoryStorage: environment.basePath + environment.adminService + 'memoryStorage',

  // web

  pageCustomization: environment.basePath + environment.adminService + 'pageCustomization',

  // customize web header

  header: environment.basePath + environment.adminService + 'header',

  // customize web footer

  footer: environment.basePath + environment.adminService + 'footer',

  // customize main banner

  mainBanner: environment.basePath + environment.adminService + 'mainBanner',

  // customize bottom call to action

  bottomCallAction: environment.basePath + environment.adminService + 'bottomCallAction',

  // customize browse programs

  browsePrograms: environment.basePath + environment.adminService + 'browsePrograms',

  // customize free media items

  displayMediaItemInfo: environment.basePath + environment.webService + 'displayMediaItemInfo',

  // customize all platforms

  allPlatForms: environment.basePath + environment.adminService + 'allPlatForms',

  // customize web settings

  webAppSetting: environment.basePath + environment.adminService + 'webAppSetting',

  // sample templates

  content: environment.basePath + environment.adminService + 'content',

  // setting changed status

  settingChangedRequest: environment.basePath + environment.adminService + 'changedRequest',

  // super admin setting

  settings: environment.basePath + environment.adminService + 'settings',

  // video

  video: environment.basePath + environment.adminService + 'video',


  //  PutSubscription & GetSubscription
  subscriptionPlans: environment.basePath + environment.adminService + 'feature',

  //CSVdownload

  csvDownload: environment.basePath + environment.adminService + 'csvDownload',

  //replyMessage

  contactUs: environment.basePath + environment.webService + 'contactUs',

  //livestreaming

  liveStreaming: environment.basePath + environment.adminService + 'liveStreaming',

  //livestreamsetting

  liveStreamingSetting: environment.basePath + environment.adminService + 'liveStreamingSetting',

  //webhook

  webhook: environment.basePath + environment.adminService + 'webhook',

  //antMedia Websocket

  antMediaWebSocket: environment.antMediaWebsocket,

  antMediaRecordWebsocket: environment.antMediaRecordWebsocket,

  //antmedia server url

  antMediaServerUrl: environment.antMediaServerUrl,

  //socialMedia Livestreaming

  socialMediaLiveStreaming: environment.basePath + environment.adminService + 'socialMediaLiveStreaming',

  invoice: environment.basePath + environment.adminService + 'invoice',

  schedulingChannelInfo: environment.basePath + environment.adminService + 'schedulingChannelInfo',

  //transcoding 

  videoTranscoding: environment.basePath + environment.adminService + 'videoTranscoding',
  //chatsupport server url 
  chatSupportServiceUrl:environment.basePath + 'chat' ,

  //chatt Support websocket url 

  chatSupportWebSocket: environment.chatSupprotWebsocket + '/chat',
  loginWebsocketToken: environment.basePath + 'users/',
  customDomainURL: environment.customDomainURL + 'run_script',
  customDomainWWWURL: environment.customDomainURL + 'wwwrun_script',
  dataUsageInfo: environment.basePath + environment.adminService + 'dataUsageInfo'
};

