import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { CustomerService } from "src/app/services/customer/customer.service";
import { MediaService } from "src/app/services/media/media.service";
import { RequestService } from "src/app/services/request/request.service";
import { TranscodingService } from "src/app/services/transcoding/transcoding.service";

@Injectable(
    {
        providedIn: 'root'
    }
)
export class OnDemandListResolver {
    constructor(
        public mediaService: MediaService,
    ) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return Promise.all([
            this.mediaService.getMediaItems(1, 5, '', 'MEDIA_ITEM', '').toPromise().then(data => data),
            this.mediaService.getMediaSeries(1, 4, '', 'MEDIA_SERIES').toPromise().then(data => data),
        ]);
    }
}
