import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PodcastService } from "src/app/services/podcast/podcast.service";
import { SubscriptionServiceService } from "src/app/services/subscription/subscription-service.service";

@Injectable(
    {
        providedIn: 'root'
    }
)
export class OneTimeResolver {
    constructor(public subscriptionService: SubscriptionServiceService) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return this.subscriptionService.getOneTimeSubscribedUserList(1, 10, '').toPromise().then(data => data.data)
    }
}