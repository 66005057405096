import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { GivingServiceService } from "src/app/services/giving/giving-service.service";
import * as moment from 'moment-timezone';;

@Injectable({
  providedIn: 'root'
})
export class GivingStatementListResolver  {
  constructor(public givingService: GivingServiceService) {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDays = new Date(y, m, 1);
    var lastDays = new Date(y, m + 1, 0);
    var firsts = moment(firstDays).format("YYYY-MM-DD");
    var lasts = moment(lastDays).format("YYYY-MM-DD");
    this.startDate = firsts;
    this.endDate = lasts;
    this.mainStartDate = firsts;
    this.mainEndDate = lasts;
    this.page = 1;
  }
  endDate = "";
  startDate = "";
  page: number = 1;
  rows: number = 10;
  mainStartDate = "";
  mainEndDate = "";
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.givingService
      .getGivingStatementListData(
        1,
        10,
        "",
        this.mainEndDate,
        this.mainStartDate,
        ""
      )
      .toPromise()
      .then((data) => data.data);
  }
}
