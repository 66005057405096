import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { RequestService } from "src/app/services/request/request.service";
import { SettingsServiceService } from "src/app/services/settings/settings-service.service";

@Injectable({
    providedIn: 'root'
})
export class SettingResolver {
    constructor(public settingsService: SettingsServiceService) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return Promise.all([
            this.settingsService.getGeneralSettingList().toPromise().then(data => data),
            this.settingsService.getSuperAdminSetting().toPromise().then(data => data),
        ]);
    }
}