import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PodcastService } from "src/app/services/podcast/podcast.service";
import { RequestService } from "src/app/services/request/request.service";

@Injectable(
    {
        providedIn: 'root'
    }
)
export class PodcastResolver {
    constructor(public podcastService: PodcastService) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return this.podcastService.getPodcastList(1, 8, '').toPromise().then(data => data.data)
    }
}