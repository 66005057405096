import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { LibraryServiceService } from "src/app/services/library/library-service.service";

@Injectable({
    providedIn: 'root'
})
export class RsslistResolver {
    constructor(public libraryService: LibraryServiceService) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return this.libraryService.getRssFeed(1, 5, '').toPromise().then(data => data.data)
    }
}