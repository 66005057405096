import { Injectable } from '@angular/core';
import {appConfig} from 'src/app/app.config'
import { ResourceService } from '../resource/resource.service';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ContactusService {
 
  constructor(  
    public resourceService : ResourceService,
    public http:HttpClient
    ) { }


  getqueryMessages(page,size,search,setStatus,startDate,endDate){
    const end = endDate ? `endDate=${endDate}&` : ''
    const start = startDate ? `&startDate=${startDate}` : ''
    // const status = setStatus ? `&setStatus=${setStatus}` : ''
    let status 
    if(setStatus=== true){
       status = `&setStatus=${setStatus}`
    }
    if(setStatus === false){
       status = `&setStatus=${setStatus}`
    }
    if(status === undefined){
       status = ''
    }
    if(!page){
      page = 1
    }

    if(!size){
      size = 10
    }
   

    const filter = (search ? `&searchkey=${search}` : '');

  const getMessageDataURL = `${appConfig.queryMessage}?${end}page=${page}${status}&size=${size}${filter}${start}`
    return this.resourceService.getData(getMessageDataURL)
  }

  /* getMessage By Id */

  getMessagebyid(id){
    const getMessageByIdURL = `${appConfig.queryMessage}/${id}`
    return this.resourceService.getData(getMessageByIdURL)
  }



  // ContactUsCsvDownload

  downloadCsv(page,searchkey,size,downloadType){
    const filter = (searchkey ? `&searchkey=${searchkey}` : '');
  const download = `${appConfig.csvDownload}/ContactUsCsvDownload?downloadType=${downloadType}&page=${page}${filter}&size=${size}`
    return this.http.get(download,{responseType: 'blob'})
  }


  //saveReplyMessage
  saveReplyMessage(id,data){
    const save = `${appConfig. contactUs}/saveReplyMessage/${id}`
    return this.resourceService.postData(save,data)
  }

  //getReplyMessage

  getReplyMessage(id){
    const getReply = `${appConfig. contactUs}/getAllReplyMessage/${id}`
    return this.resourceService.getData(getReply)


  
 
 

  }
  //upload
  uploadFile(formData:any, type:any) {
    const uploadFileUrl = `${appConfig.upload}/${type}`;
    return this.resourceService.postData(uploadFileUrl, formData);
  }

  //updateReplyMessageStatus

  updateReplyMessageStatus(id,messageReplyId,setStatus) {
    const MessageStatus = `${appConfig. contactUs}/updateReplyMessageStatus/${id}?messageReplyId=${messageReplyId}&setStatus=${setStatus}`
    return this.resourceService.putData(MessageStatus);
  }
  //
  contactUsMessageStatus(id,setStatus) {
    const MessageStatus = `${appConfig.queryMessage}/${id}?setStatus=${setStatus}`
    return this.resourceService.putData(MessageStatus);
  }

  getAllContactUsByReadAndUnread(page,size,setStatus) {
    const MessageStatus = `${appConfig.queryMessage}/getAllContactUsByReadAndUnread?page=${page}&setStatus=${setStatus}&size=${size}`
    return this.resourceService.getData(MessageStatus);
  }
  
  

  uploadBase64(stockData: any) {
    const uploadBaseUrl = `${appConfig.upload}/base64images`;
    return this.resourceService.postData(uploadBaseUrl, stockData);
  }


}




