import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {appConfig} from 'src/app/app.config'
import { ResourceService } from 'src/app/services/resource/resource.service'
import { HttpClient } from '@angular/common/http';
import { type } from 'os';
@Injectable({
  providedIn: 'root'
})
export class ContactServiceService {

  constructor(
    public resourceService : ResourceService,
    public http:HttpClient
  ) { }

  /* create contact */

  createContact(data){
    const createContactURL = `${appConfig.contact}`
    return this.resourceService.postData(createContactURL,data)
  }

  /* get Contact list */

  getContactList(page, size, search, endDate, startDate, isDefault, isSelected, userSubscriptionPlanStatus){
    // const item = (isSelected ? `&itemType=${itemType}` : `?itemType=${itemType}`)
    const userSubscriptionPlan = userSubscriptionPlanStatus ? `&userSubscriptionPlanStatus=${userSubscriptionPlanStatus}` : ''

    const selected = isSelected ? `&isSelected=${isSelected}` : ''
    const end = endDate ? `?endDate=${endDate}` : ''
    const filter = search ? `&searchKey=${search}` : ''
    const start = startDate ? `&startDate=${startDate}` : ''
    const DefaultData = (endDate ? `&isDefault=${isDefault}`: `?isDefault=${isDefault}` 
    
    )
    // isDefault ? `isDefault=${isDefault}` : ''
    const getContactListURL = `${appConfig.contact}${end}${DefaultData}${selected}&page=${page}${filter}&size=${size}${start}${userSubscriptionPlan}`
    return this.resourceService.getData(getContactListURL)
  }

  /* get Contact by Id */

  getContactById(id){
    const getContactByIdURL = `${appConfig.contact}/${id}`
    return this.resourceService.getData(getContactByIdURL)
  }
  
  getInvoiceDataById(page,size,id){
    const getContactByIdURL = `${appConfig.contact}/invoice/${id}?page=${page}&size=${size}`
    return this.resourceService.getData(getContactByIdURL)
  }

  getGivingDataById( id, type, page, size) {
    const filter = type === 'giving' ? "GIVING" : "ONE_TIME"
    // const giving = "GIVING"
    const getContactByIdURL = `${appConfig.contact}/${id}/invoice?invoiceType=${filter}&page=${page}&size=${size}`
    return this.resourceService.getData(getContactByIdURL)
  }

  /* get card details */

  getCardDetailById(id) {
    // const filter = type === 'giving' ? "GIVING" : "ONE_TIME"
    const getContactByIdURL = `${appConfig.contact}/${id}/cardInfo`
    return this.resourceService.getData(getContactByIdURL)
  }

  /* delete customer */

  deleteContact(id){
    const deleteCustomerUrl = `${appConfig.contact}/${id}`
    return this.resourceService.deleteData(deleteCustomerUrl)
  }

  /* update contact */

  updateContact(id,data){
    const updateContactURL = `${appConfig.contact}/${id}`
    return this.resourceService.putData(updateContactURL,data)
  }

  /* contact signup */

  signup(uuid,data){
    const signupURL = `${appConfig.signUp}?uuid=${uuid}`
    return this.resourceService.postData(signupURL,data)
  }

  getSubscriptionData(){
    const getSubsciptionDataURL = `${appConfig.subscription}`
    return this.resourceService.getData(getSubsciptionDataURL)
  }


  // downloadCsv(){
  //   const download = `${appConfig.csvDownload}/ContactCsvDownload`
  //   return this.http.get(download,{responseType: 'blob'})
  // }
  downloadCsv(page,searchkey,size,downloadType){
    const download = `${appConfig.csvDownload}/ContactCsvDownload?downloadType=${downloadType}&page=${page}&searchkey=${searchkey}&size=${size}`
    return this.http.get(download,{responseType: 'blob'})
  }

  syncGHLKey(orgId:number) {
    const createGeneralSettingURL = `${appConfig.contact}/goHighLevel/contacts`;
    return this.resourceService.postData(createGeneralSettingURL);
  }

}
