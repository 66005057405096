import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { CustomerService } from "src/app/services/customer/customer.service";
import { RequestService } from "src/app/services/request/request.service";
import { TranscodingService } from "src/app/services/transcoding/transcoding.service";

@Injectable({
    providedIn: 'root'
})
export class TranscodingResolver {
    constructor(
        public transcodingService: TranscodingService,
        public customerService: CustomerService
    ) {
    }
    page: number = 1;
    rows: number = 10;
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return Promise.all([
            this.transcodingService.getTranscodingData(this.page, this.rows, '', '', '','').toPromise().then(data => data),
            this.customerService.getAllOrganizationListInSort().toPromise().then(data => data),
        ]);
    }
}
