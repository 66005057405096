import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AppsService } from "src/app/services/apps/apps-service";
import { AuthenticateService } from "src/app/services/authenticateService/authenticate.service";

@Injectable({
    providedIn: 'root'
})
export class MainMobileTabResolver {
    initialTabId: number;
    data1: any;
    constructor(
        public appsService: AppsService,
        public authService: AuthenticateService,
    ) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const loginUserDetail = localStorage.getItem('loggedInUser');
        const orgId = loginUserDetail ? JSON.parse(loginUserDetail).userDetails.organization.id : '';
        return Promise.all([
            this.appsService.getActiveTabs().toPromise().then(data => data ),
            this.authService.getBrandingDataWithoutAuth(orgId).toPromise().then(data => data),
        ])
        .catch(function(err) {
        //   console.log(err.message); // some coding error in handling happened
        });
    }
}