import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { GivingServiceService } from "src/app/services/giving/giving-service.service";
import * as moment from 'moment-timezone';


@Injectable({
    providedIn:'root'
})
export class GivingListResolver {
    constructor(public givingService: GivingServiceService) {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDays = new Date(y, m, 1);
        var lastDays = new Date(y, m + 1, 0);
        var firsts = moment(firstDays).format('YYYY-MM-DD')
        var lasts = moment(lastDays).format('YYYY-MM-DD')
        this.startDate = firsts
        this.endDate = lasts
        this.isDefault = true
        this.page = 1
    }
    endDate = ''
    startDate = ''
    orderObj: any
    page: number = 1
    rows: number = 10
    queryParamsValue: any
    isDefault: boolean;
    filterValue: any;
    selectedFilter: string = 'month';


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        console.log(route.queryParams)
        this.queryParamsValue = route.queryParams.page ? route.queryParams : ''
        // if(route.queryParams.forRange){
        //     this.startDate = null
        //     this.endDate = null
        //     this.filterValue = 'range'
        //     this.selectedFilter = 'range'
        // }
        if (this.queryParamsValue) {
            this.page = route.queryParams.page
        }
        return this.givingService.getGiving(this.page, this.rows, '', this.endDate, this.startDate, true, '').toPromise().then(data => data.data)
    }
}