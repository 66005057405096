import { Routes } from '@angular/router';
import { LoginComponent } from './components/authenticate/login/login.component';
import { LoginAuthGuard } from './auth-guards/login-auth.guard';
import { UpgradeSubscriptionPlanComponent } from './components/authenticate/upgrade-subscription-plan/upgrade-subscription-plan.component';
import { EmailVerificationComponent } from './components/authenticate/email-verification/email-verification.component';
import { SignupOrgComponent } from './components/authenticate/signup-org/signup-org.component';
import { ResetPasswordComponent } from './components/authenticate/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './components/authenticate/forget-password/forget-password.component';
import { TvPreviewComponent } from './components/authenticate/tv-preview/tv-preview.component';
import { PlatformsComponent } from './components/authenticate/platforms/platforms.component';
import { SignupComponent } from './components/authenticate/signup/signup.component';
import { InitialComponent } from './components/shared/initial/initial.component';
import { AuthGuard } from './auth-guards/auth.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardResolver } from './components/dashboard/dashboard.service';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { SubscriptionDetailComponent } from './components/subscription/subscription-detail/subscription-detail.component';
import { SubscriptionDetailResolver } from './components/subscription/subscription-detail/subscription-detail.resolver';
import { EditSubscriptionComponent } from './components/subscription/edit-subscription/edit-subscription.component';
import { CreateCustomPlanComponent } from './components/subscription/create-custom-plan/create-custom-plan.component';
import { SendMailComponent } from './components/customer/send-mail/send-mail.component';
import { TemplateListComponent } from './components/customer/template-list/template-list.component';
import { AddCustomerComponent } from './components/customer/add-customer/add-customer.component';
import { CustomerListResolver } from './components/customer/customer-list/customer.resolver';
import { CustomerListComponent } from './components/customer/customer-list/customer-list.component';
import { CustomerComponent } from './components/customer/customer.component';
import { EditorComponent } from './components/customer/email-editor/email-editor.component';
import { EmailTemplatesComponent } from './components/email-templates/email-templates.component';
import { TemplateTypesComponent } from './components/email-templates/template-types/template-types.component';
import { MassTemplateComponent } from './components/email-templates/mass-template/mass-template.component';
import { ViewInvoiceComponent } from './components/accounting/view-invoice/view-invoice.component';
import { AddAccountComponent } from './components/accounting/add-account/add-account.component';
import { RevenueResolver } from './components/accounting/revenue/revenue.resolver';
import { RevenueComponent } from './components/accounting/revenue/revenue.component';
import { ExpenseResolver } from './components/accounting/expense/expense.resolver';
import { ExpenseComponent } from './components/accounting/expense/expense.component';
import { AccountingComponent } from './components/accounting/accounting.component';
import { SettingResolver } from './components/admin-setting/general/general.resolver';
import { GeneralComponent } from './components/admin-setting/general/general.component';
import { AdminSettingComponent } from './components/admin-setting/admin-setting.component';
import { AddOnboardingUserComponent } from './components/onboarding/add-onboarding-user/add-onboarding-user.component';
import { OnboardingResolver } from './components/onboarding/onboarding-list/onboarding.resolver';
import { OnboardingListComponent } from './components/onboarding/onboarding-list/onboarding-list.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { ViewdetailComponent } from './components/request/viewdetail/viewdetail.component';
import { RequestsResolver } from './components/request/requests/requests.resolver';
import { RequestsComponent } from './components/request/requests/requests.component';
import { RequestComponent } from './components/request/request.component';
import { TranscodingResolver } from './components/transcode/transcoding-list/transcoding-list.resolver';
import { TranscodingListComponent } from './components/transcode/transcoding-list/transcoding-list.component';
import { TranscodingComponent } from './components/transcode/transcoding/transcoding.component';
import { AddSecurityComponent } from './components/security/add-security/add-security.component';
import { SecurityResolver } from './components/security/security-list/security-list.resolver';
import { SecurityListComponent } from './components/security/security-list/security-list.component';
import { SecurityComponent } from './components/security/security.component';
import { EmbedComponent } from './components/media/embed/embed.component';
import { AnalyticsComponent } from './components/media/analytics/analytics.component';
import { CreateCollectionsComponent } from './components/media/create-collections/create-collections.component';
import { CreateEbookComponent } from './components/media/create-ebook/create-ebook.component';
import { EbookListResolver } from './components/media/ebook/ebooklist/ebooklist.resolver';
import { EbooklistComponent } from './components/media/ebook/ebooklist/ebooklist.component';
import { EbookComponent } from './components/media/ebook/ebook.component';
import { FolderMusicComponent } from './components/media/music/folder-music/folder-music.component';
import { CreateSongComponent } from './components/media/music/create-song/create-song.component';
import { CreateAlbumComponent } from './components/media/music/create-album/create-album.component';
import { MusicResolver } from './components/media/music/music.resolver';
import { MusicComponent } from './components/media/music/music.component';
import { PodcastlistComponent } from './components/media/podcast/podcast-list/podcast-list.component';
import { PodcastResolver } from './components/media/podcast/podcast.resolver';
import { PodcastComponent } from './components/media/podcast/podcast.component';
import { CreateLiveStreamComponent } from './components/media/live-streaming/create-live-stream/create-live-stream.component';
import { LivestreamingResolver } from './components/media/live-streaming/live-stream-list/live-stream.resolver';
import { LiveStreamListComponent } from './components/media/live-streaming/live-stream-list/live-stream-list.component';
import { LiveStreamingComponent } from './components/media/live-streaming/live-streaming.component';
import { CreateMediaSeriesComponent } from './components/media/create-media-series/create-media-series.component';
import { CreateMediaItemComponent } from './components/media/create-media-item/create-media-item.component';
import { OnDemandListResolver } from './components/media/on-demand/on-demand-list/on-demand-list.resolver';
import { OnDemandListComponent } from './components/media/on-demand/on-demand-list/on-demand-list.component';
import { OnDemandComponent } from './components/media/on-demand/on-demand.component';
import { MediaComponent } from './components/media/media.component';
import { StockDetailComponent } from './components/upload-stock/stock-detail/stock-detail.component';
import { StockInfoComponent } from './components/upload-stock/stock-info/stock-info.component';
import { StockListComponent } from './components/upload-stock/stock-list/stock-list.component';
import { UploadStockComponent } from './components/upload-stock/upload-stock.component';
import { AppsComponent } from './components/apps/apps.component';
import { CollectionComponent } from './components/library/collection/collection.component';
import { PagesComponent } from './components/library/pages/pages.component';
import { FillInNotesComponent } from './components/library/fill-in-notes/fill-in-notes.component';
import { CreateRssItemsComponent } from './components/library/create-rss-items/create-rss-items.component';
import { RsslistResolver } from './components/library/rss-list/rss-list.resolver';
import { RssListComponent } from './components/library/rss-list/rss-list.component';
import { RssFeedsComponent } from './components/library/rss-feeds/rss-feeds.component';
import { CreateLinkComponent } from './components/library/create-link/create-link.component';
import { LinklistResolver } from './components/library/link-list/link-list.resolver';
import { LinkListComponent } from './components/library/link-list/link-list.component';
import { LinksComponent } from './components/library/links/links.component';
import { CreateListComponent } from './components/library/create-list/create-list.component';
import { FolderListResolver } from './components/library/folder-list/folder-list.resolver';
import { FolderListComponent } from './components/library/folder-list/folder-list.component';
import { FolderComponent } from './components/library/folder/folder.component';
import { LibraryComponent } from './components/library/library.component';
import { AppDownloadsComponent } from './components/apps/analytics/app-downloads/app-downloads.component';
import { PushNotificationSubscribersComponent } from './components/apps/push-notification/push-notification-subscribers/push-notification-subscribers.component';
import { CreateNotificationComponent } from './components/apps/push-notification/create-notification/create-notification.component';
import { PushNotificationResolver } from './components/apps/push-notification/push-notification.resolver';
import { PushNotificationComponent } from './components/apps/push-notification/push-notification.component';
import { TvAppComponent } from './components/apps/tv-app/tv-app.component';
import { ManagetabsComponent } from './components/apps/mobile-app/managetabs/managetabs.component';
import { MainMobileTabResolver } from './components/apps/mobile-app/mainmobiletab/mainmobiletab.resolver';
import { MainmobiletabComponent } from './components/apps/mobile-app/mainmobiletab/mainmobiletab.component';
import { VODComponent } from './components/apps/mobile-app/vod/vod.component';
import { WelcomeComponent } from './components/apps/mobile-app/welcome/welcome.component';
import { MobileAppComponent } from './components/apps/mobile-app/mobile-app.component';
import { EmbedesComponent } from './components/events/embedes/embedes.component';
import { CreateCalenderComponent } from './components/events/create-calender/create-calender.component';
import { RegisterdUserdetailsComponent } from './components/events/registerd-userdetails/registerd-userdetails.component';
import { CreateEventComponent } from './components/events/create-event/create-event.component';
import { EventListResolver } from './components/events/event-list/event-list.resolver';
import { EventListComponent } from './components/events/event-list/event-list.component';
import { EventsComponent } from './components/events/events.component';
import { IntegrationsComponent } from './components/settings/integrations/integrations.component';
import { IntegrationSettingsComponent } from './components/settings/integration-settings/integration-settings.component';
import { MailsResolver } from './components/settings/mails/mails.resolver';
import { MailsComponent } from './components/settings/mails/mails.component';
import { StripSettingResolver } from './components/settings/stripe-settings/stripe-settings.resolver';
import { StripeSettingsComponent } from './components/settings/stripe-settings/stripe-settings.component';
import { SubscriptionResolver } from './components/settings/subscription/subscription.resolver';
import { InvitedUserDataComponent } from './components/settings/invited-user-data/invited-user-data.component';
import { UpdateUserComponent } from './components/settings/update-user/update-user.component';
import { UsersInfoComponent } from './components/settings/users-info/users-info.component';
import { UsersResolver } from './components/settings/users/users.resolver';
import { UsersComponent } from './components/settings/users/users.component';
import { BrandingResolver } from './components/settings/branding/branding.resolver';
import { BrandingComponent } from './components/settings/branding/branding.component';
import { TaxInfoResolver } from './components/settings/tax-info/tax-info.resolver';
import { TaxInfoComponent } from './components/settings/tax-info/tax-info.component';
import { AppStoreResolver } from './components/settings/app-store-info/app-store-info.resolver';
import { AppStoreInfoComponent } from './components/settings/app-store-info/app-store-info.component';
import { RokuDeveloperComponent } from './components/settings/roku-developer/roku-developer.component';
import { PlaystoreDeveloperComponent } from './components/settings/playstore-developer/playstore-developer.component';
import { AppleDeveloperComponent } from './components/settings/apple-developer/apple-developer.component';
import { DeveloperAccountsComponent } from './components/settings/developer-accounts/developer-accounts.component';
import { GeneralSettingsDataComponent } from './components/settings/general-settings-data/general-settings-data.component';
import { GeneralSettingsComponent } from './components/settings/general-settings/general-settings.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TemplateComponent } from './components/contacts/template/template.component';
import { OrganizationTemplateTypesComponent } from './components/organization-email-templates/organization-template-types/organization-template-types.component';
import { OrganizationEmailTemplatesComponent } from './components/organization-email-templates/organization-email-templates.component';
import { MassTemplatesComponent } from './components/organization-email-templates/mass-templates/mass-templates.component';
import { ContactHistoryComponent } from './components/contacts/contact-history/contact-history.component';
import { SignupFormComponent } from './components/contacts/signup-form/signup-form.component';
import { AddContactsComponent } from './components/contacts/add-contacts/add-contacts.component';
import { SendMessageComponent } from './components/contacts/send-message/send-message.component';
import { SendMassEmailComponent } from './components/contacts/send-mass-email/send-mass-email.component';
import { ContactsListComponent } from './components/contacts/contacts-list/contacts-list.component';
import { ContactListResolver } from './components/contacts/contacts-list/contacts-list.resolver';
import { ContactsComponent } from './components/contacts/contacts.component';
import { SubscriptionTemplateComponent } from './components/subscription-template/subscription-template.component';
import { OneTimeResolver } from './components/subscription-module/onetime-subscriber/onetime-subscriber.resolver';
import { OnetimeSubscriberComponent } from './components/subscription-module/onetime-subscriber/onetime-subscriber.component';
import { SubcribedUserResolver } from './components/subscription-module/subscribed-user/subscribed-user.resolver';
import { FreeSubscriberComponent } from './components/subscription-module/free-subscriber/free-subscriber.component';
import { SubscribedUserComponent } from './components/subscription-module/subscribed-user/subscribed-user.component';
import { CreatePlanComponent } from './components/subscription-module/subscription-plan/create-plan/create-plan.component';
import { SubscriptionPlanResolver } from './components/subscription-module/subscription-plan/subscription-plan.resolver';
import { SubscriptionPlanComponent } from './components/subscription-module/subscription-plan/subscription-plan.component';
import { SubscriptionModuleComponent } from './components/subscription-module/subscription-module.component';
import { ViewqueryComponent } from './components/contactus-module/viewquery/viewquery.component';
import { QueryListResolver } from './components/contactus-module/querylist/querylist.resolver';
import { QuerylistComponent } from './components/contactus-module/querylist/querylist.component';
import { SettingsResolver } from './components/contactus-module/settings/settings.resolver';
import { ContactusModuleComponent } from './components/contactus-module/contactus-module.component';
import { SocialNotificationComponent } from './components/social/social-notification/social-notification.component';
import { LeadFormsComponent } from './components/social/lead-forms/lead-forms.component';
import { LeadAdFormsComponent } from './components/social/lead-ad-forms/lead-ad-forms.component';
import { BasicInfoComponent } from './components/social/social-media/add-social-media/basic-info/basic-info.component';
import { AddSocialMediaComponent } from './components/social/social-media/add-social-media/add-social-media.component';
import { SocialMediaResolver } from './components/social/social-media/social-media.resolver';
import { SocialMediaComponent } from './components/social/social-media/social-media.component';
import { SocialComponent } from './components/social/social.component';
import { GivingDetailsComponent } from './components/giving/giving-details/giving-details.component';
import { GivingStatementDetailsComponent } from './components/giving/giving-statement-details/giving-statement-details.component';
import { GivingStatementListResolver } from './components/giving/giving-statement-list/giving-statement-list.resolver';
import { GivingStatementListComponent } from './components/giving/giving-statement-list/giving-statement-list.component';
import { GivingStatementComponent } from './components/giving/giving-statement/giving-statement.component';
import { GivingListResolver } from './components/giving/giving-list/giving-list.resolver';
import { GivingListComponent } from './components/giving/giving-list/giving-list.component';
import { GivingComponent } from './components/giving/giving.component';
import { EditMenuComponent } from './components/web/edit-menu/edit-menu.component';
import { AddMenuComponent } from './components/web/add-menu/add-menu.component';
import { NavigationMenuComponent } from './components/web/navigation-menu/navigation-menu.component';
import { NavigationComponent } from './components/web/navigation/navigation.component';
import { CreatePageComponent } from './components/web/create-page/create-page.component';
import { CustomizePageComponent } from './components/web/customize-page/customize-page.component';
import { CustomizeWebComponent } from './components/web/customize-web/customize-web.component';
import { WebAppComponent } from './components/web/web-app/web-app.component';
import { WebAppResolver } from './components/web/web-app/web-app.resolve';
import { MainWebComponent } from './components/web/main-web/main-web.component';
import { WebComponent } from './components/web/web.component';
import { ChannelSeriesComponent } from './components/channel/channel-series/channel-series.component';
import { AddChannelComponent } from './components/channel/add-channel/add-channel.component';
import { ChannelResolver } from './components/channel/channel-list/channel-list.resolver';
import { ChannelListComponent } from './components/channel/channel-list/channel-list.component';
import { ChannelComponent } from './components/channel/channel.component';
import { PaymentComponent } from './components/payment/payment.component';
import { MyProfileComponent } from './components/profile/my-profile/my-profile.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AddUntrainedDataComponent } from './components/chat-support/add-untrained-data/add-untrained-data.component';
import { UntrainedDataComponent } from './components/chat-support/untrained-data/untrained-data.component';
import { ViewChatHistoryComponent } from './components/chat-support/view-chat-history/view-chat-history.component';
import { AddTrainingDataComponent } from './components/chat-support/add-training-data/add-training-data.component';
import { TrainingDataComponent } from './components/chat-support/training-data/training-data.component';
import { ChatHistoryComponent } from './components/chat-support/chat-history/chat-history.component';
import { ChatSupportComponent } from './components/chat-support/chat-support.component';
import { AddLiveStreamComponent } from './components/data-usage/cost-calculator/add-live-stream/add-live-stream.component';
import { LiveStreamUsageComponent } from './components/data-usage/cost-calculator/live-stream-usage/live-stream-usage.component';
import { AddVodTransferCostingComponent } from './components/data-usage/cost-calculator/add-vod-transfer-costing/add-vod-transfer-costing.component';
import { AddStorageCostingComponent } from './components/data-usage/cost-calculator/add-storage-costing/add-storage-costing.component';
import { VodDataTransferCostingComponent } from './components/data-usage/cost-calculator/vod-data-transfer-costing/vod-data-transfer-costing.component';
import { StorageCostingComponent } from './components/data-usage/cost-calculator/storage-costing/storage-costing.component';
import { CostCalculatorComponent } from './components/data-usage/cost-calculator/cost-calculator.component';
import { DataUsageListComponent } from './components/data-usage/data-usage-list/data-usage-list.component';
import { DataUsageComponent } from './components/data-usage/data-usage.component';
import { SignupResolver } from './components/authenticate/signup/signup.resolver';
import { PlatformResolver } from './components/authenticate/platforms/platforms.resolver';
import { TvAppResolver } from './components/authenticate/tv-preview/tv-preview.resolver';

export const routes: Routes = [
    {
        path: 'login',
        loadComponent: () => import('./components/authenticate/login/login.component').then((m) => m.LoginComponent),
        // component: LoginComponent,
        canActivate: [LoginAuthGuard]
    },
    {
        path: 'signup/:uuid',
        loadComponent: () => import('./components/authenticate/signup/signup.component').then((m) => m.SignupComponent),
        resolve: { signupData: SignupResolver }
        // component: SignupComponent,
    },
    {
        path: 'platforms',
        loadComponent: () => import('./components/authenticate/platforms/platforms.component').then((m) => m.PlatformsComponent),
        resolve: { platformData: PlatformResolver }
        // component: PlatformsComponent,
    },
    {
        path: 'tv-app-preview',
        loadComponent: () => import('./components/authenticate/tv-preview/tv-preview.component').then((m) => m.TvPreviewComponent),
        resolve: { tvAppData: TvAppResolver }
        // component: TvPreviewComponent,
    },
    {
        path: 'forget-password',
        loadComponent: () => import('./components/authenticate/forget-password/forget-password.component').then((m) => m.ForgetPasswordComponent),
        // component: ForgetPasswordComponent,
    },
    {
        path: 'reset-password',
        loadComponent: () => import('./components/authenticate/reset-password/reset-password.component').then((m) => m.ResetPasswordComponent),
        // component: ResetPasswordComponent,
    },
    {
        path: 'set-password',
        loadComponent: () => import('./components/authenticate/reset-password/reset-password.component').then((m) => m.ResetPasswordComponent),
        // component: ResetPasswordComponent,
    },
    {
        path: 'organizationSignup',
        loadComponent: () => import('./components/authenticate/signup-org/signup-org.component').then((m) => m.SignupOrgComponent),
        // component: SignupOrgComponent,
    },
    {
        path: 'organizationSignup/:id',
        loadComponent: () => import('./components/authenticate/signup-org/signup-org.component').then((m) => m.SignupOrgComponent),
        resolve: { data: SubscriptionPlanResolver }
        // component: SignupOrgComponent,
    },
    {
        path: 'email-verification',
        loadComponent: () => import('./components/authenticate/email-verification/email-verification.component').then((m) => m.EmailVerificationComponent),
        // component: EmailVerificationComponent,
    },
    {
        path: 'data-usage-email',
        loadComponent: () => import('./components/authenticate/upgrade-subscription-plan/upgrade-subscription-plan.component').then((m) => m.UpgradeSubscriptionPlanComponent),
        // component: UpgradeSubscriptionPlanComponent,
    },
    {
        path: "",
        loadComponent: () => import('./components/shared/initial/initial.component').then((m) => m.InitialComponent),
        // component: InitialComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: "",
                pathMatch: "full",
                redirectTo: "dashboard",
            },
            {
                path: "dashboard",
                loadComponent: () => import('./components/dashboard/dashboard.component').then((m) => m.DashboardComponent),
                // component: DashboardComponent,
                resolve: { data: DashboardResolver },
                data: {
                    header: "Dashboard",
                },
            },
            {
                path: 'customer-module',
                children: [
                    {
                        path: 'customer',
                        loadComponent: () => import('./components/customer/customer.component').then((m) => m.CustomerComponent),
                        // component: CustomerComponent,
                        data: {
                            header: 'Customers',
                        },
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('./components/customer/customer-list/customer-list.component').then((m) => m.CustomerListComponent),
                                // component: CustomerListComponent,
                                resolve: { data: CustomerListResolver },
                                data: {
                                    header: 'Customers',
                                },
                            },
                            {
                                path: 'edit-customer',
                                loadComponent: () => import('./components/customer/add-customer/add-customer.component').then((m) => m.AddCustomerComponent),
                                // component: AddCustomerComponent,
                                data: {
                                    header: 'Customer Info',
                                }
                            },
                            {
                                path: 'edit-customer/:id',
                                loadComponent: () => import('./components/customer/add-customer/add-customer.component').then((m) => m.AddCustomerComponent),
                                // component: AddCustomerComponent,
                                data: {
                                    header: 'Edit Customer Info',
                                }
                            },
                            {
                                path: 'email-template',
                                loadComponent: () => import('./components/customer/template-list/template-list.component').then((m) => m.TemplateListComponent),
                                // component: TemplateListComponent,
                                data: {
                                    header: 'Customers',
                                }
                            },
                            {
                                path: 'send-mail/:id',
                                loadComponent: () => import('./components/customer/send-mail/send-mail.component').then((m) => m.SendMailComponent),
                                // component: SendMailComponent,
                                data: {
                                    header: 'Customers',
                                }
                            },
                        ]
                    },
                ]
            },

            //   {
            //     path: "customer-module",
            //     loadChildren: () =>
            //       import("./modules/customer/customer.module").then(
            //         (m) => m.CustomerModule
            //       ),
            //   },
            {
                path: "email-templates-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'email-temp',
                        pathMatch: 'full'
                    },
                    {
                        path: 'template-type',
                        loadComponent: () => import('./components/email-templates/mass-template/mass-template.component').then((m) => m.MassTemplateComponent),
                        // component : MassTemplateComponent,
                        data: {
                            header: 'Email Templates',
                        },
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('./components/email-templates/template-types/template-types.component').then((m) => m.TemplateTypesComponent),
                                // component : TemplateTypesComponent,
                                data: {
                                    header: 'Email Templates'
                                },
                            },
                            {
                                path: 'email-template',
                                loadComponent: () => import('./components/email-templates/email-templates.component').then((m) => m.EmailTemplatesComponent),
                                // component : EmailTemplatesComponent,
                                data: {
                                    header: 'Email Templates'
                                },
                            },
                            {
                                path: 'create-template',
                                loadComponent: () => import('./components/customer/email-editor/email-editor.component').then((m) => m.EditorComponent),
                                // component: EditorComponent,
                                data: {
                                    header: 'Email Templates',
                                }
                            },
                            {
                                path: 'create-template/:id',
                                loadComponent: () => import('./components/customer/email-editor/email-editor.component').then((m) => m.EditorComponent),
                                // component: EditorComponent,
                                data: {
                                    header: 'Email Templates',
                                }
                            },
                        ]
                    },
                ]
                // loadChildren: () =>
                //   import("./modules/email-templates/email-templates.module").then(
                //     (m) => m.EmailTemplatesModule
                //   ),
            },
            {
                path: "subscription",
                loadComponent: () => import('./components/subscription/subscription.component').then((m) => m.SubscriptionComponent),
                // component: SubscriptionComponent,
                data: {
                    header: "Subscription",
                },
                children: [
                    {
                        path: "",
                        loadComponent: () => import('./components/subscription/subscription-detail/subscription-detail.component').then((m) => m.SubscriptionDetailComponent),
                        // component: SubscriptionDetailComponent,
                        resolve: { data: SubscriptionDetailResolver },
                        data: {
                            header: "Subscription",
                        },
                    },
                    {
                        path: "edit-subscription/:id/:name",
                        loadComponent: () => import('./components/subscription/edit-subscription/edit-subscription.component').then((m) => m.EditSubscriptionComponent),
                        // component: EditSubscriptionComponent,
                        data: {
                            header: "Subscription",
                        },
                    },
                    {
                        path: "create-custom-plan",
                        loadComponent: () => import('./components/subscription/create-custom-plan/create-custom-plan.component').then((m) => m.CreateCustomPlanComponent),
                        // component: CreateCustomPlanComponent,
                        data: {
                            header: "Subscription",
                        },
                    },
                    {
                        path: "edit-custom-plan/:id/:name",
                        loadComponent: () => import('./components/subscription/create-custom-plan/create-custom-plan.component').then((m) => m.CreateCustomPlanComponent),
                        // component: CreateCustomPlanComponent,
                        data: {
                            header: "Subscription",
                        },
                    },
                ],
            },
            {
                path: "accounting-module",
                children: [
                    {
                        path: 'accounting',
                        loadComponent: () => import('./components/accounting/accounting.component').then((m) => m.AccountingComponent),
                        // component: AccountingComponent,
                        data: {
                            header: 'Accounting',
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'revenue',
                                pathMatch: "full"
                            },
                            {
                                path: 'expense',
                                loadComponent: () => import('./components/accounting/expense/expense.component').then((m) => m.ExpenseComponent),
                                // component: ExpenseComponent,
                                resolve: { data: ExpenseResolver },
                                data: {
                                    header: 'Accounting',
                                },
                            },
                            {
                                path: 'revenue',
                                loadComponent: () => import('./components/accounting/revenue/revenue.component').then((m) => m.RevenueComponent),
                                // component: RevenueComponent,
                                resolve: { data: RevenueResolver },
                                data: {
                                    header: 'Accounting',
                                }
                            },
                            {
                                path: 'edit-account',
                                loadComponent: () => import('./components/accounting/add-account/add-account.component').then((m) => m.AddAccountComponent),
                                // component: AddAccountComponent,
                                data: {
                                    header: 'Accounting',
                                }
                            },
                            {
                                path: 'edit-account/:id',
                                loadComponent: () => import('./components/accounting/add-account/add-account.component').then((m) => m.AddAccountComponent),
                                // component: AddAccountComponent,
                                data: {
                                    header: 'Accounting',
                                }
                            },
                            {
                                path: 'view-invoice/:id',
                                loadComponent: () => import('./components/accounting/view-invoice/view-invoice.component').then((m) => m.ViewInvoiceComponent),
                                // component: ViewInvoiceComponent,
                                data: {
                                    header: 'Accounting',
                                }
                            },

                        ]
                    },
                ]
                // loadChildren: () =>
                //   import("./modules/accounting/accounting.module").then(
                //     (m) => m.AccountingModule
                //   ),
            },
            {
                path: "admin-setting-module",
                children: [
                    {
                        path: 'admin-setting',
                        loadComponent: () => import('./components/admin-setting/admin-setting.component').then((m) => m.AdminSettingComponent),
                        // component: AdminSettingComponent,
                        data: {
                            header: 'Settings',
                        },
                        children: [
                            {
                                path: 'general',
                                loadComponent: () => import('./components/admin-setting/general/general.component').then((m) => m.GeneralComponent),
                                // component: GeneralComponent,
                                resolve: { data: SettingResolver },
                                data: {
                                    header: 'Settings',
                                },
                            }
                        ]
                    },
                ]
                // loadChildren: () =>
                //   import("./modules/admin-setting/admin-setting.module").then(
                //     (m) => m.AdminSettingModule
                //   ),
            },
            {
                path: "onboarding-module",
                children: [
                    {
                        path: 'onboarding',
                        loadComponent: () => import('./components/onboarding/onboarding.component').then((m) => m.OnboardingComponent),
                        // component: OnboardingComponent,
                        data: {
                            header: 'Onboarding Users',
                        },
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('./components/onboarding/onboarding-list/onboarding-list.component').then((m) => m.OnboardingListComponent),
                                // component: OnboardingListComponent,
                                resolve: { data: OnboardingResolver },
                                data: {
                                    header: 'Onboarding Users'
                                }
                            },
                            {
                                path: 'edit-onboarding-user',
                                loadComponent: () => import('./components/onboarding/add-onboarding-user/add-onboarding-user.component').then((m) => m.AddOnboardingUserComponent),
                                // component: AddOnboardingUserComponent,
                                data: {
                                    header: 'Onboarding Users'
                                }
                            },
                            {
                                path: 'edit-onboarding-user/:id',
                                loadComponent: () => import('./components/onboarding/add-onboarding-user/add-onboarding-user.component').then((m) => m.AddOnboardingUserComponent),
                                // component: AddOnboardingUserComponent,
                                data: {
                                    header: 'Onboarding Users'
                                }
                            },
                        ]
                    },
                ]
                // loadChildren: () =>
                //   import("./modules/onboarding/onboarding.module").then(
                //     (m) => m.OnBoardingModule
                //   ),
            },

            {
                path: "requestcutomer-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'requestcustomer',
                        pathMatch: 'full'
                    },
                    {
                        path: 'requestcustomer',
                        loadComponent: () => import('./components/request/request.component').then((m) => m.RequestComponent),
                        // component: RequestComponent,
                        data: {
                            header: 'Request Customers',
                        },
                        children: [

                            {
                                path: '',
                                loadComponent: () => import('./components/request/requests/requests.component').then((m) => m.RequestsComponent),
                                // component: RequestsComponent,
                                resolve: { data: RequestsResolver },
                                data: {
                                    header: 'Request Customers',
                                },
                            },

                            {
                                path: 'vieweditcustomer/:id',
                                loadComponent: () => import('./components/request/viewdetail/viewdetail.component').then((m) => m.ViewdetailComponent),
                                // component: ViewdetailComponent,
                                data: {
                                    header: 'Request Customers',
                                },

                            },
                        ]
                    }
                ]
                // loadChildren: () =>
                //   import("./modules/requestcustomer/requestcustomer.module").then(
                //     (m) => m.RequestCustomerModule
                //   ),
            },
            {
                path: "transcoding-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'transcoding',
                        pathMatch: 'full'
                    },
                    {
                        path: 'transcoding',
                        loadComponent: () => import('./components/transcode/transcoding/transcoding.component').then((m) => m.TranscodingComponent),
                        // component: TranscodingComponent,
                        data: {
                            header: 'Transcoding',
                        },
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('./components/transcode/transcoding-list/transcoding-list.component').then((m) => m.TranscodingListComponent),
                                // component: TranscodingListComponent,
                                resolve: { data: TranscodingResolver },
                                data: {
                                    header: 'Transcoding'
                                }
                            },

                        ]
                    },
                ]
                // loadChildren: () =>
                //   import("./modules/transcoding/transcoding.module").then(
                //     (m) => m.TranscodingModule
                //   ),
            },
            {
                path: "security-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'security',
                        pathMatch: 'full'
                    },
                    {
                        path: 'security',
                        loadComponent: () => import('./components/security/security.component').then((m) => m.SecurityComponent),
                        // component: SecurityComponent,
                        data: {
                            header: 'Security Group',
                        },
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('./components/security/security-list/security-list.component').then((m) => m.SecurityListComponent),
                                // component: SecurityListComponent,
                                resolve: { data: SecurityResolver },
                                data: {
                                    header: 'Security Group'
                                }
                            },
                            {
                                path: 'edit-security-group',
                                loadComponent: () => import('./components/security/add-security/add-security.component').then((m) => m.AddSecurityComponent),
                                // component: AddSecurityComponent,
                                data: {
                                    header: 'Add Security Group'
                                }
                            },
                            {
                                path: 'edit-security-group/:id',
                                loadComponent: () => import('./components/security/add-security/add-security.component').then((m) => m.AddSecurityComponent),
                                // component: AddSecurityComponent,
                                data: {
                                    header: 'Edit Security Group'
                                }
                            }
                        ]
                    },
                ]
                // loadChildren: () =>
                //   import("./modules/security/security.module").then(
                //     (m) => m.SecurityModule
                //   ),
            },
            {
                path: "media-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'media',
                        pathMatch: 'full'
                    },
                    {
                        path: 'media',
                        loadComponent: () => import('./components/media/media.component').then((m) => m.MediaComponent),
                        // component: MediaComponent,
                        data: {
                            header: 'Media Assets',
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'on-demand',
                                pathMatch: 'full'
                            },
                            {
                                path: 'on-demand',
                                loadComponent: () => import('./components/media/on-demand/on-demand.component').then((m) => m.OnDemandComponent),
                                // component: OnDemandComponent,
                                data: {
                                    header: 'Media Assets'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/media/on-demand/on-demand-list/on-demand-list.component').then((m) => m.OnDemandListComponent),
                                        // component: OnDemandListComponent,
                                        resolve: { data: OnDemandListResolver }
                                    },
                                    {
                                        path: 'edit-media-item/:id',
                                        loadComponent: () => import('./components/media/create-media-item/create-media-item.component').then((m) => m.CreateMediaItemComponent),
                                        // component: CreateMediaItemComponent
                                    },
                                    {
                                        path: 'edit-media-series/:id',
                                        loadComponent: () => import('./components/media/create-media-series/create-media-series.component').then((m) => m.CreateMediaSeriesComponent),
                                        // component: CreateMediaSeriesComponent
                                    }
                                ]
                            },
                            {
                                path: 'live-streaming',
                                loadComponent: () => import('./components/media/live-streaming/live-streaming.component').then((m) => m.LiveStreamingComponent),
                                // component: LiveStreamingComponent,
                                data: {
                                    header: 'Live Streaming'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/media/live-streaming/live-stream-list/live-stream-list.component').then((m) => m.LiveStreamListComponent),
                                        // component: LiveStreamListComponent,
                                        resolve: { data: LivestreamingResolver }
                                    },
                                    {
                                        path: 'edit-live-stream/:id',
                                        loadComponent: () => import('./components/media/live-streaming/create-live-stream/create-live-stream.component').then((m) => m.CreateLiveStreamComponent),
                                        // component: CreateLiveStreamComponent
                                    },
                                    {
                                        path: 'edit-live-stream',
                                        loadComponent: () => import('./components/media/live-streaming/create-live-stream/create-live-stream.component').then((m) => m.CreateLiveStreamComponent),
                                        // component: CreateLiveStreamComponent
                                    }
                                ]
                            },
                            {
                                path: 'podcast',

                                data: {
                                    header: 'Media Assets'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/media/podcast/podcast.component').then((m) => m.PodcastComponent),
                                        // component: PodcastComponent,
                                        resolve: { data: PodcastResolver }
                                    },
                                    {
                                        path: 'edit-podcast/:id',
                                        loadComponent: () => import('./components/media/podcast/podcast-list/podcast-list.component').then((m) => m.PodcastlistComponent),
                                        // component: PodcastlistComponent
                                    },
                                    {
                                        path: 'pobcastlist',
                                        loadComponent: () => import('./components/media/podcast/podcast-list/podcast-list.component').then((m) => m.PodcastlistComponent),
                                        // component: PodcastlistComponent
                                    }
                                ]
                            },
                            {
                                path: 'music',

                                data: {
                                    header: 'Media Assets'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/media/music/music.component').then((m) => m.MusicComponent),
                                        // component: MusicComponent,
                                        resolve: { data: MusicResolver }
                                    },
                                    {
                                        path: 'createAlbum/:id',
                                        loadComponent: () => import('./components/media/music/create-album/create-album.component').then((m) => m.CreateAlbumComponent),
                                        // component: CreateAlbumComponent
                                    },
                                    {
                                        path: 'createSong/:id',
                                        loadComponent: () => import('./components/media/music/create-song/create-song.component').then((m) => m.CreateSongComponent),
                                        // component: CreateSongComponent
                                    },
                                    {
                                        path: 'createFolder/:id/:getFrom',
                                        loadComponent: () => import('./components/media/music/folder-music/folder-music.component').then((m) => m.FolderMusicComponent),
                                        // component: FolderMusicComponent
                                    }
                                ]
                            },
                            {
                                path: 'ebook',
                                loadComponent: () => import('./components/media/ebook/ebook.component').then((m) => m.EbookComponent),
                                // component: EbookComponent,
                                data: {
                                    header: 'Media Assets'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/media/ebook/ebooklist/ebooklist.component').then((m) => m.EbooklistComponent),
                                        // component: EbooklistComponent,
                                        resolve: { data: EbookListResolver }
                                    },
                                    {
                                        path: 'edit-ebook/:id',
                                        loadComponent: () => import('./components/media/create-ebook/create-ebook.component').then((m) => m.CreateEbookComponent),
                                        // component: CreateEbookComponent
                                    },
                                    {
                                        path: 'edit-collection/:id',
                                        loadComponent: () => import('./components/media/create-collections/create-collections.component').then((m) => m.CreateCollectionsComponent),
                                        // component: CreateCollectionsComponent
                                    }
                                ]
                            },
                            {
                                path: 'analytics',
                                loadComponent: () => import('./components/media/analytics/analytics.component').then((m) => m.AnalyticsComponent),
                                // component: AnalyticsComponent,
                                data: {
                                    header: 'Media Assets'
                                }
                            },
                            {
                                path: 'embeds',
                                loadComponent: () => import('./components/media/embed/embed.component').then((m) => m.EmbedComponent),
                                // component: EmbedComponent,
                                data: {
                                    header: 'Media Assets'
                                }
                            },
                        ]
                    },
                ]
                // loadChildren: () =>
                //   import("./modules/media/media.module").then((m) => m.MediaModule),
            },
            {
                path: "stock-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'upload-stock',
                        pathMatch: 'full'
                    },
                    {
                        path: 'upload-stock',
                        loadComponent: () => import('./components/upload-stock/upload-stock.component').then((m) => m.UploadStockComponent),
                        // component: UploadStockComponent,
                        data: {
                            header: 'Upload Stock',
                        },
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('./components/upload-stock/stock-list/stock-list.component').then((m) => m.StockListComponent),
                                // component: StockListComponent,
                                data: {
                                    header: 'Upload Stock',
                                },
                            },
                            {
                                path: 'upload',
                                loadComponent: () => import('./components/upload-stock/stock-info/stock-info.component').then((m) => m.StockInfoComponent),
                                // component: StockInfoComponent,
                                data: {
                                    header: 'Upload Stock',
                                },
                            },
                            {
                                path: 'upload/:stockId',
                                loadComponent: () => import('./components/upload-stock/stock-detail/stock-detail.component').then((m) => m.StockDetailComponent),
                                // component: StockDetailComponent,
                                data: {
                                    header: 'Upload Stock',
                                },
                            }
                        ]
                    },
                ]
                // loadChildren: () =>
                //   import("./modules/upload-stock/upload-stock.module").then(
                //     (m) => m.UploadStockModule
                //   ),
            },
            {
                path: "library-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'library',
                        pathMatch: 'full'
                    },
                    {
                        path: 'library',
                        loadComponent: () => import('./components/library/library.component').then((m) => m.LibraryComponent),
                        // component: LibraryComponent,
                        data: {
                            header: 'Media Assets'
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'folder',
                                pathMatch: 'full'
                            },
                            {
                                path: 'folder',
                                loadComponent: () => import('./components/library/folder/folder.component').then((m) => m.FolderComponent),
                                // component: FolderComponent,
                                data: {
                                    header: 'Media Assets'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/library/folder-list/folder-list.component').then((m) => m.FolderListComponent),
                                        // component: FolderListComponent,
                                        resolve: { data: FolderListResolver }
                                    },
                                    {
                                        path: 'create-list/:id/:getFrom',
                                        loadComponent: () => import('./components/library/create-list/create-list.component').then((m) => m.CreateListComponent),
                                        // component: CreateListComponent
                                    }
                                ]
                            },
                            {
                                path: 'links',
                                loadComponent: () => import('./components/library/links/links.component').then((m) => m.LinksComponent),
                                // component: LinksComponent,
                                data: {
                                    header: 'Media Assets'
                                }
                                ,
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/library/link-list/link-list.component').then((m) => m.LinkListComponent),
                                        // component: LinkListComponent,
                                        resolve: { data: LinklistResolver }
                                    },
                                    {
                                        path: 'create-link',
                                        loadComponent: () => import('./components/library/create-link/create-link.component').then((m) => m.CreateLinkComponent),
                                        // component: CreateLinkComponent
                                    },
                                    {
                                        path: 'create-link/:id',
                                        loadComponent: () => import('./components/library/create-link/create-link.component').then((m) => m.CreateLinkComponent),
                                        // component: CreateLinkComponent
                                    }
                                ]
                            },
                            {
                                path: 'rss-feeds',
                                loadComponent: () => import('./components/library/rss-feeds/rss-feeds.component').then((m) => m.RssFeedsComponent),
                                // component: RssFeedsComponent,
                                data: {
                                    header: 'Media Assets'
                                }
                                ,
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/library/rss-list/rss-list.component').then((m) => m.RssListComponent),
                                        // component: RssListComponent,
                                        resolve: { data: RsslistResolver }
                                    },
                                    {
                                        path: 'create-rss-item',
                                        loadComponent: () => import('./components/library/create-rss-items/create-rss-items.component').then((m) => m.CreateRssItemsComponent),
                                        // component: CreateRssItemsComponent
                                    },
                                    {
                                        path: 'create-rss-item/:id',
                                        loadComponent: () => import('./components/library/create-rss-items/create-rss-items.component').then((m) => m.CreateRssItemsComponent),
                                        // component: CreateRssItemsComponent
                                    }
                                ]
                            },
                            {
                                path: 'fill-in-Notes',
                                loadComponent: () => import('./components/library/fill-in-notes/fill-in-notes.component').then((m) => m.FillInNotesComponent),
                                // component: FillInNotesComponent,
                                data: {
                                    header: 'Media Assets'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/library/pages/pages.component').then((m) => m.PagesComponent),
                                        // component: PagesComponent
                                    },
                                    {
                                        path: 'pages',
                                        loadComponent: () => import('./components/library/pages/pages.component').then((m) => m.PagesComponent),
                                        // component: PagesComponent
                                    },
                                    {
                                        path: 'collection',
                                        loadComponent: () => import('./components/library/collection/collection.component').then((m) => m.CollectionComponent),
                                        // component: CollectionComponent
                                    },
                                    {
                                        path: 'apps',
                                        loadComponent: () => import('./components/apps/apps.component').then((m) => m.AppsComponent),
                                        // component: AppsComponent

                                    }

                                ]
                            },
                        ]
                    }
                ]

                // loadChildren: () =>
                //   import("./modules/library/library.module").then(
                //     (m) => m.LibraryModule
                //   ),
            },
            {
                path: "apps-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'apps',
                        pathMatch: 'full',
                    },
                    {
                        path: 'apps',
                        loadComponent: () => import('./components/apps/apps.component').then((m) => m.AppsComponent),
                        // component: AppsComponent,
                        data: {
                            header: 'Apps'
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'mobileApp',
                                pathMatch: 'full'
                            },
                            {
                                path: 'mobileApp',
                                loadComponent: () => import('./components/apps/mobile-app/mobile-app.component').then((m) => m.MobileAppComponent),
                                // component: MobileAppComponent,
                                data: {
                                    header: 'Apps'
                                },
                                children: [
                                    {
                                        path: '',
                                        redirectTo: 'MobileApp',
                                        pathMatch: 'full'
                                    },
                                    {
                                        path: 'MobileApp',
                                        loadComponent: () => import('./components/apps/mobile-app/mainmobiletab/mainmobiletab.component').then((m) => m.MainmobiletabComponent),
                                        // component: MainmobiletabComponent,
                                        resolve: { data: MainMobileTabResolver },
                                        data: {
                                            header: 'Apps'
                                        },
                                        children: [
                                            {
                                                path: '',
                                                redirectTo: 'welcome',
                                                pathMatch: 'full'
                                            },
                                            {
                                                path: 'welcome',
                                                loadComponent: () => import('./components/apps/mobile-app/welcome/welcome.component').then((m) => m.WelcomeComponent),
                                                // component: WelcomeComponent
                                            },
                                            {
                                                path: 'VOD',
                                                loadComponent: () => import('./components/apps/mobile-app/vod/vod.component').then((m) => m.VODComponent),
                                                // component: VODComponent
                                            },
                                        ]
                                    },
                                    {
                                        path: 'view-tab/:id/:tabType',
                                        loadComponent: () => import('./components/apps/mobile-app/mainmobiletab/mainmobiletab.component').then((m) => m.MainmobiletabComponent),
                                        // component: MainmobiletabComponent,
                                        resolve: { data: MainMobileTabResolver },
                                    },
                                    {
                                        path: 'manageTabs',
                                        loadComponent: () => import('./components/apps/mobile-app/managetabs/managetabs.component').then((m) => m.ManagetabsComponent),
                                        // component: ManagetabsComponent
                                    },
                                ]
                            },
                            {
                                path: 'tvApp',
                                loadComponent: () => import('./components/apps/tv-app/tv-app.component').then((m) => m.TvAppComponent),
                                // component: TvAppComponent,
                                data: {
                                    header: 'Apps'
                                }
                            },

                            {
                                path: 'pushNotification',

                                data: {
                                    header: 'Apps'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/apps/push-notification/push-notification.component').then((m) => m.PushNotificationComponent),
                                        // component: PushNotificationComponent,
                                        resolve: { data: PushNotificationResolver },
                                    },
                                    {
                                        path: 'createNotification',
                                        loadComponent: () => import('./components/apps/push-notification/create-notification/create-notification.component').then((m) => m.CreateNotificationComponent),
                                        // component: CreateNotificationComponent
                                    },
                                    {
                                        path: 'editNotification/:id',
                                        loadComponent: () => import('./components/apps/push-notification/create-notification/create-notification.component').then((m) => m.CreateNotificationComponent),
                                        // component: CreateNotificationComponent
                                    },
                                    {
                                        path: 'createNotification/:media',
                                        loadComponent: () => import('./components/apps/push-notification/create-notification/create-notification.component').then((m) => m.CreateNotificationComponent),
                                        // component: CreateNotificationComponent
                                    },
                                    {
                                        path: 'pushNotiSubscribers',
                                        loadComponent: () => import('./components/apps/push-notification/push-notification-subscribers/push-notification-subscribers.component').then((m) => m.PushNotificationSubscribersComponent),
                                        //  component: PushNotificationSubscribersComponent
                                    },
                                ]
                            },
                            {
                                path: 'analytics',
                                data: {
                                    header: 'Apps'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/apps/analytics/analytics.component').then((m) => m.AnalyticsComponent),
                                        // component: AnalyticsComponent,
                                    },
                                    {
                                        path: 'appDownloads',
                                        loadComponent: () => import('./components/apps/analytics/app-downloads/app-downloads.component').then((m) => m.AppDownloadsComponent),
                                        // component: AppDownloadsComponent,
                                    },

                                ]
                            },
                        ]
                    }
                ]
                // loadChildren: () =>
                //   import("./modules/apps/apps.module").then((m) => m.AppsModule),
            },
            {
                path: "events-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'events',
                        pathMatch: 'full'
                    },
                    {
                        path: 'events',
                        loadComponent: () => import('./components/events/events.component').then((m) => m.EventsComponent),
                        // component: EventsComponent,
                        data: {
                            header: 'Events'
                        },

                        children: [
                            {
                                path: '',
                                redirectTo: 'eventList',
                                pathMatch: 'full'
                            },
                            {
                                path: 'eventList',
                                loadComponent: () => import('./components/events/event-list/event-list.component').then((m) => m.EventListComponent),
                                // component: EventListComponent,
                                resolve: { data: EventListResolver },
                                data: {
                                    header: 'Events'
                                },
                            },
                            {
                                path: 'create-event',
                                loadComponent: () => import('./components/events/create-event/create-event.component').then((m) => m.CreateEventComponent),
                                // component: CreateEventComponent,
                                data: {
                                    header: 'Events'
                                },
                            },
                            {
                                path: 'edit-event/:id',
                                loadComponent: () => import('./components/events/create-event/create-event.component').then((m) => m.CreateEventComponent),
                                // component: CreateEventComponent,
                                data: {
                                    header: 'Events'
                                },
                            },
                            {
                                path: 'create-calender',
                                loadComponent: () => import('./components/events/create-calender/create-calender.component').then((m) => m.CreateCalenderComponent),
                                // component: CreateCalenderComponent,
                                data: {
                                    header: 'Events'
                                },
                            },

                            {
                                path: 'eventregister-userslist/:id',
                                loadComponent: () => import('./components/events/registerd-userdetails/registerd-userdetails.component').then((m) => m.RegisterdUserdetailsComponent),
                                // component: RegisterdUserdetailsComponent,
                                data: {
                                    header: 'User details'
                                },
                            },

                            {
                                path: 'edit-calender/:id',
                                loadComponent: () => import('./components/events/create-calender/create-calender.component').then((m) => m.CreateCalenderComponent),
                                // component: CreateCalenderComponent,
                                data: {
                                    header: 'Events'
                                },
                            },
                            {
                                path: 'embeds',
                                loadComponent: () => import('./components/events/embedes/embedes.component').then((m) => m.EmbedesComponent),
                                // component: EmbedesComponent,
                                data: {
                                    header: 'Events'
                                },
                            },
                        ]
                    }
                ]
                // loadChildren: () =>
                //   import("./modules/events/events.module").then((m) => m.EventsModule),
            },
            {
                path: "settings-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'settings',
                        pathMatch: 'full'
                    },
                    {
                        path: 'settings',
                        loadComponent: () => import('./components/settings/settings.component').then((m) => m.SettingsComponent),
                        // component: SettingsComponent,
                        data: {
                            header: 'Settings'
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'general-settings',
                                pathMatch: 'full'
                            },
                            {
                                path: 'general-settings',
                                loadComponent: () => import('./components/settings/general-settings/general-settings.component').then((m) => m.GeneralSettingsComponent),
                                // component: GeneralSettingsComponent,
                                data: {
                                    header: 'Settings'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/settings/general-settings-data/general-settings-data.component').then((m) => m.GeneralSettingsDataComponent),
                                        // component: GeneralSettingsDataComponent
                                    },
                                ]
                            },
                            {
                                path: 'developers-account',
                                data: {
                                    header: 'Settings'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/settings/developer-accounts/developer-accounts.component').then((m) => m.DeveloperAccountsComponent),
                                        // component: DeveloperAccountsComponent
                                    },
                                    {
                                        path: 'apple-developer',
                                        loadComponent: () => import('./components/settings/apple-developer/apple-developer.component').then((m) => m.AppleDeveloperComponent),
                                        // component: AppleDeveloperComponent
                                    },
                                    {
                                        path: 'playstore-developer',
                                        loadComponent: () => import('./components/settings/playstore-developer/playstore-developer.component').then((m) => m.PlaystoreDeveloperComponent),
                                        // component: PlaystoreDeveloperComponent
                                    },
                                    {
                                        path: 'roku-developer',
                                        loadComponent: () => import('./components/settings/roku-developer/roku-developer.component').then((m) => m.RokuDeveloperComponent),
                                        // component: RokuDeveloperComponent
                                    }
                                ]
                            },
                            {
                                path: 'app-store-info',
                                loadComponent: () => import('./components/settings/app-store-info/app-store-info.component').then((m) => m.AppStoreInfoComponent),
                                // component: AppStoreInfoComponent,
                                resolve: { data: AppStoreResolver },
                                data: {
                                    header: 'Settings'
                                }
                            },
                            {
                                path: 'tax-info',
                                loadComponent: () => import('./components/settings/tax-info/tax-info.component').then((m) => m.TaxInfoComponent),
                                // component: TaxInfoComponent,
                                resolve: { data: TaxInfoResolver },

                                data: {
                                    header: 'Settings'
                                },
                            },
                            {
                                path: 'branding',
                                loadComponent: () => import('./components/settings/branding/branding.component').then((m) => m.BrandingComponent),
                                // component: BrandingComponent,
                                resolve: { data: BrandingResolver },
                                data: {
                                    header: 'Settings'
                                }
                            },
                            {
                                path: 'users',
                                data: {
                                    header: 'Settings'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/settings/users/users.component').then((m) => m.UsersComponent),
                                        // component: UsersComponent,
                                        resolve: { data: UsersResolver },
                                    },
                                    {
                                        path: 'users-info',
                                        loadComponent: () => import('./components/settings/users-info/users-info.component').then((m) => m.UsersInfoComponent),
                                        // component: UsersInfoComponent
                                    },
                                    {
                                        path: 'update-user/:id',
                                        loadComponent: () => import('./components/settings/update-user/update-user.component').then((m) => m.UpdateUserComponent),
                                        // component: UpdateUserComponent
                                    },
                                    {
                                        path: 'invited-user/:id',
                                        loadComponent: () => import('./components/settings/invited-user-data/invited-user-data.component').then((m) => m.InvitedUserDataComponent),
                                        // component: InvitedUserDataComponent
                                    }
                                ]
                            },
                            {
                                path: 'subscription',
                                loadComponent: () => import('./components/settings/subscription/subscription.component').then((m) => m.SubscriptionComponent),
                                // component: SubscriptionComponent,
                                resolve: { data: SubscriptionResolver },
                                data: {
                                    header: 'Settings'
                                }
                            },
                            {
                                path: 'stripe-settings',
                                loadComponent: () => import('./components/settings/stripe-settings/stripe-settings.component').then((m) => m.StripeSettingsComponent),
                                // component: StripeSettingsComponent,
                                resolve: { data: StripSettingResolver },
                                data: {
                                    header: 'Settings'
                                }
                            },
                            {
                                path: 'mails',
                                loadComponent: () => import('./components/settings/mails/mails.component').then((m) => m.MailsComponent),
                                // component: MailsComponent,
                                resolve: { data: MailsResolver },
                                data: {
                                    header: 'Settings'
                                }
                            },
                            {
                                path: 'integrations-settings',
                                data: {
                                    header: 'Integrations Settings'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/settings/integration-settings/integration-settings.component').then((m) => m.IntegrationSettingsComponent),
                                        // component: IntegrationSettingsComponent
                                    },
                                    {
                                        path: 'ghlIntegrations',
                                        loadComponent: () => import('./components/settings/integrations/integrations.component').then((m) => m.IntegrationsComponent),
                                        // component: IntegrationsComponent,
                                    }
                                ]
                            }

                        ]
                    }
                ]
                // loadChildren: () =>
                //   import("./modules/settings/settings.module").then(
                //     (m) => m.SettingsModule
                //   ),
            },
            {
                path: "organization-email-templates-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'organiation-templates',
                        pathMatch: 'full'
                    },
                    {
                        path: 'organiation-templates',
                        loadComponent: () => import('./components/organization-email-templates/mass-templates/mass-templates.component').then((m) => m.MassTemplatesComponent),
                        // component : MassTemplatesComponent,
                        data: {
                            header: 'Email Templates'
                        },
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('./components/organization-email-templates/organization-email-templates.component').then((m) => m.OrganizationEmailTemplatesComponent),
                                // component : OrganizationEmailTemplatesComponent,
                                data: {
                                    header: 'Email Templates'
                                }
                            },
                            {
                                path: 'organiation-templates-types',
                                loadComponent: () => import('./components/organization-email-templates/organization-template-types/organization-template-types.component').then((m) => m.OrganizationTemplateTypesComponent),
                                // component : OrganizationTemplateTypesComponent,
                                data: {
                                    header: 'Email Templates'
                                }
                            },
                            {
                                path: 'email-template',
                                loadComponent: () => import('./components/contacts/template/template.component').then((m) => m.TemplateComponent),
                                // component : TemplateComponent,
                                data: {
                                    header: 'Email Templates',
                                },
                            },
                            {
                                path: 'email-template/:id',
                                loadComponent: () => import('./components/contacts/template/template.component').then((m) => m.TemplateComponent),
                                // component : TemplateComponent,
                                data: {
                                    header: 'Email Templates',
                                },
                            },

                        ]
                    }
                ]
                // loadChildren: () =>
                //   import(
                //     "./modules/organization-email-templates/organization-email-templates.module"
                //   ).then((m) => m.OrganizationEmailTemplatesModule),
            },
            {
                path: "contacts-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'contacts',
                        pathMatch: 'full'
                    },
                    {
                        path: 'contacts',
                        loadComponent: () => import('./components/contacts/contacts.component').then((m) => m.ContactsComponent),
                        // component: ContactsComponent,
                        data: {
                            header: 'Contacts'
                        },
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('./components/contacts/contacts-list/contacts-list.component').then((m) => m.ContactsListComponent),
                                // component: ContactsListComponent,
                                resolve: { data: ContactListResolver },
                                data: {
                                    header: 'Contacts',
                                },
                            },
                            {
                                path: 'send-mass-email',
                                loadComponent: () => import('./components/contacts/send-mass-email/send-mass-email.component').then((m) => m.SendMassEmailComponent),
                                // component: SendMassEmailComponent,
                                data: {
                                    header: 'Contacts',
                                },
                            },
                            {
                                path: 'send-message/:id',
                                loadComponent: () => import('./components/contacts/send-message/send-message.component').then((m) => m.SendMessageComponent),
                                // component: SendMessageComponent,
                                data: {
                                    header: 'Contacts',
                                },
                            },
                            {
                                path: 'add-contacts',
                                loadComponent: () => import('./components/contacts/add-contacts/add-contacts.component').then((m) => m.AddContactsComponent),
                                // component: AddContactsComponent,
                                data: {
                                    header: 'Contacts',
                                },
                            },
                            {
                                path: 'add-contacts/:id',
                                loadComponent: () => import('./components/contacts/add-contacts/add-contacts.component').then((m) => m.AddContactsComponent),
                                // component: AddContactsComponent,
                                data: {
                                    header: 'Contacts',
                                },
                            },
                            {
                                path: 'sign-up',
                                loadComponent: () => import('./components/contacts/signup-form/signup-form.component').then((m) => m.SignupFormComponent),
                                // component: SignupFormComponent,
                                data: {
                                    header: 'Signup Form'
                                }
                            },
                            {
                                path: 'contact-history',
                                loadComponent: () => import('./components/contacts/contact-history/contact-history.component').then((m) => m.ContactHistoryComponent),
                                // component: ContactHistoryComponent,
                                data: {
                                    header: 'Contacts'
                                }
                            }
                        ]
                    }
                ]
                // loadChildren: () =>
                //   import("./modules/contacts/contacts.module").then(
                //     (m) => m.ContactsModule
                //   ),
            },
            {
                path: "subscription-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'subscription-m',
                        pathMatch: 'full'
                    },
                    {
                        path: 'subscription-m',
                        loadComponent: () => import('./components/subscription-module/subscription-module.component').then((m) => m.SubscriptionModuleComponent),
                        // component: SubscriptionModuleComponent,
                        data: {
                            header: 'Subscription'
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'subscription-plan',
                                pathMatch: 'full'
                            },
                            {
                                path: 'subscription-plan',

                                data: {
                                    header: 'Subscription-plan'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/subscription-module/subscription-plan/subscription-plan.component').then((m) => m.SubscriptionPlanComponent),
                                        // component: SubscriptionPlanComponent,
                                        resolve: { data: SubscriptionPlanResolver }
                                    },
                                    {
                                        path: 'create-plan',
                                        loadComponent: () => import('./components/subscription-module/subscription-plan/create-plan/create-plan.component').then((m) => m.CreatePlanComponent),
                                        // component: CreatePlanComponent
                                    },
                                    {
                                        path: 'create-plan/:id',
                                        loadComponent: () => import('./components/subscription-module/subscription-plan/create-plan/create-plan.component').then((m) => m.CreatePlanComponent),
                                        // component: CreatePlanComponent
                                    }
                                ]
                            },
                            {
                                path: 'subscribed-user',
                                loadComponent: () => import('./components/subscription-module/subscribed-user/subscribed-user.component').then((m) => m.SubscribedUserComponent),
                                // component: SubscribedUserComponent,
                                resolve: { data: SubcribedUserResolver },
                                data: {
                                    header: 'Subscription'
                                },
                            },

                            {
                                path: 'free-subscriber',
                                loadComponent: () => import('./components/subscription-module/free-subscriber/free-subscriber.component').then((m) => m.FreeSubscriberComponent),
                                // component: FreeSubscriberComponent,
                                resolve: { data: SubcribedUserResolver },
                                data: {
                                    header: 'Subscription'
                                },
                            },
                            {
                                path: 'onetime-paidsubscriber',
                                loadComponent: () => import('./components/subscription-module/onetime-subscriber/onetime-subscriber.component').then((m) => m.OnetimeSubscriberComponent),
                                // component: OnetimeSubscriberComponent,
                                resolve: { data: OneTimeResolver },

                                data: {
                                    header: 'Subscription'
                                }
                            },
                            {
                                path: 'subscription-template',
                                loadComponent: () => import('./components/subscription-template/subscription-template.component').then((m) => m.SubscriptionTemplateComponent),
                                // component: SubscriptionTemplateComponent,
                                data: {
                                    header: 'Subscription'
                                }
                            },
                        ]
                    }
                ]
                // loadChildren: () =>
                //   import(
                //     "./modules/subscription-module/subscription-module.module"
                //   ).then((m) => m.SubscriptionModuleModule),
            },

            {
                path: "contactus-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'contactus-m',
                        pathMatch: 'full'
                    },
                    {
                        path: 'contactus-m',
                        loadComponent: () => import('./components/contactus-module/contactus-module.component').then((m) => m.ContactusModuleComponent),
                        // component: ContactusModuleComponent,
                        data: {
                            header: 'Contact-Us'
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'settings',
                                pathMatch: 'full'
                            },
                            {
                                path: 'settings',

                                data: {
                                    header: 'Settings'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/contactus-module/settings/settings.component').then((m) => m.SettingsComponent),
                                        // component: SettingsComponent,
                                        resolve: { data: SettingsResolver }
                                    },


                                ]
                            },
                            {
                                path: 'querylist',
                                loadComponent: () => import('./components/contactus-module/querylist/querylist.component').then((m) => m.QuerylistComponent),
                                // component: QuerylistComponent,
                                resolve: { data: QueryListResolver },
                                data: {
                                    header: 'Messages List'
                                },

                            },
                            {
                                path: 'vieweditquery/:id',
                                loadComponent: () => import('./components/contactus-module/viewquery/viewquery.component').then((m) => m.ViewqueryComponent),
                                // component: ViewqueryComponent,
                                data: {
                                    header: 'Message Details',
                                },

                            },

                        ]
                    }
                ]
                // loadChildren: () =>
                //   import("./modules/contactus/contactus-module.module").then(
                //     (m) => m.ContactusModuleModule
                //   ),
            },
            {
                path: "social-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'social',
                        pathMatch: 'full'
                    },
                    {
                        path: 'social',
                        loadComponent: () => import('./components/social/social.component').then((m) => m.SocialComponent),
                        // component: SocialComponent,
                        data: {
                            header: 'Social Media'
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'social-media',
                                pathMatch: 'full'
                            },
                            {
                                path: 'social-media',

                                data: {
                                    header: 'Social Media'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/social/social-media/social-media.component').then((m) => m.SocialMediaComponent),
                                        // component: SocialMediaComponent,
                                        resolve: { data: SocialMediaResolver }
                                    },
                                    {
                                        path: 'add-social-media',

                                        data: {
                                            header: 'Social Media'
                                        },
                                        children: [
                                            {
                                                path: '',
                                                loadComponent: () => import('./components/social/social-media/add-social-media/add-social-media.component').then((m) => m.AddSocialMediaComponent),
                                                // component: AddSocialMediaComponent,
                                                data: {
                                                    header: 'Social Media'
                                                },
                                            },
                                            {
                                                path: 'basic-info',
                                                loadComponent: () => import('./components/social/social-media/add-social-media/basic-info/basic-info.component').then((m) => m.BasicInfoComponent),
                                                // component: BasicInfoComponent
                                            }
                                        ]

                                    }
                                ]
                            },
                            {
                                path: 'lead-ad-forms',
                                loadComponent: () => import('./components/social/lead-ad-forms/lead-ad-forms.component').then((m) => m.LeadAdFormsComponent),
                                // component: LeadAdFormsComponent,
                                data: {
                                    header: 'Social Media'
                                }
                            },
                            {
                                path: 'lead-forms',
                                loadComponent: () => import('./components/social/lead-forms/lead-forms.component').then((m) => m.LeadFormsComponent),
                                // component: LeadFormsComponent,
                                data: {
                                    header: 'Social Media'
                                }
                            },
                            {
                                path: 'social-notification',
                                loadComponent: () => import('./components/social/social-notification/social-notification.component').then((m) => m.SocialNotificationComponent),
                                // component: SocialNotificationComponent,
                                data: {
                                    header: 'Social Media'
                                }

                            }
                        ]
                    }
                ]
                // loadChildren: () =>
                //   import("./modules/social/social.module").then((m) => m.SocialModule),
            },
            {
                path: "giving-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'giving',
                        pathMatch: 'full'
                    },
                    {
                        path: 'giving',
                        loadComponent: () => import('./components/giving/giving.component').then((m) => m.GivingComponent),
                        // component: GivingComponent,
                        data: {
                            header: 'Giving'
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'giving-list',
                                pathMatch: 'full'
                            },
                            {
                                path: 'giving-list',
                                loadComponent: () => import('./components/giving/giving-list/giving-list.component').then((m) => m.GivingListComponent),
                                // component: GivingListComponent,
                                resolve: { data: GivingListResolver },
                                data: {
                                    header: 'Giving'
                                }
                            },
                            {
                                path: 'giving-statement',
                                loadComponent: () => import('./components/giving/giving-statement/giving-statement.component').then((m) => m.GivingStatementComponent),
                                // component: GivingStatementComponent,
                                data: {
                                    header: 'Giving'
                                }
                            },
                            {
                                path: 'giving-statement-list',
                                loadComponent: () => import('./components/giving/giving-statement-list/giving-statement-list.component').then((m) => m.GivingStatementListComponent),
                                // component: GivingStatementListComponent,
                                resolve: { data: GivingStatementListResolver },
                                data: {
                                    header: 'Giving'
                                }
                            },
                            {
                                path: 'giving-statement-details',
                                loadComponent: () => import('./components/giving/giving-statement-details/giving-statement-details.component').then((m) => m.GivingStatementDetailsComponent),
                                // component: GivingStatementDetailsComponent,
                                data: {
                                    header: 'Giving'
                                }
                            },
                            {
                                path: 'giving-statement-details/:id',
                                loadComponent: () => import('./components/giving/giving-statement-details/giving-statement-details.component').then((m) => m.GivingStatementDetailsComponent),
                                // component: GivingStatementDetailsComponent,
                                data: {
                                    header: 'Giving',
                                },
                            }
                            ,
                            {
                                path: 'giving-details',
                                loadComponent: () => import('./components/giving/giving-details/giving-details.component').then((m) => m.GivingDetailsComponent),
                                // component: GivingDetailsComponent,
                                data: {
                                    header: 'Giving'
                                }
                            },
                            {
                                path: 'giving-details/:id',
                                loadComponent: () => import('./components/giving/giving-details/giving-details.component').then((m) => m.GivingDetailsComponent),
                                // component: GivingDetailsComponent,
                                data: {
                                    header: 'Giving'
                                }
                            }
                        ]
                    }
                ]
                // loadChildren: () =>
                //   import("./modules/giving/giving.module").then((m) => m.GivingModule),
            },
            {
                path: "web-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'web',
                        pathMatch: 'full'
                    },
                    {
                        path: 'web',
                        loadComponent: () => import('./components/web/web.component').then((m) => m.WebComponent),
                        // component: WebComponent,
                        data: {
                            header: 'Website'
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'main-web',
                                pathMatch: 'full'
                            },
                            {
                                path: 'main-web',
                                loadComponent: () => import('./components/web/main-web/main-web.component').then((m) => m.MainWebComponent),
                                // component: MainWebComponent,
                                data: {
                                    header: 'Website'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/web/web-app/web-app.component').then((m) => m.WebAppComponent),
                                        // component: WebAppComponent,
                                        resolve: { data: WebAppResolver }
                                    },
                                    {
                                        path: 'web-app',
                                        loadComponent: () => import('./components/web/web-app/web-app.component').then((m) => m.WebAppComponent),
                                        // component: WebAppComponent,

                                    },
                                    {
                                        path: 'customize',
                                        loadComponent: () => import('./components/web/customize-web/customize-web.component').then((m) => m.CustomizeWebComponent),
                                        // component: CustomizeWebComponent,
                                    }

                                ]
                            },
                            {
                                path: 'customize-page',
                                loadComponent: () => import('./components/web/customize-page/customize-page.component').then((m) => m.CustomizePageComponent),
                                // component: CustomizePageComponent,
                                data: {
                                    header: 'Website'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/library/pages/pages.component').then((m) => m.PagesComponent),
                                        // component: PagesComponent
                                    },
                                    {
                                        path: 'web-pages',
                                        loadComponent: () => import('./components/library/pages/pages.component').then((m) => m.PagesComponent),
                                        // component: PagesComponent
                                    },
                                    {
                                        path: 'create-page',
                                        loadComponent: () => import('./components/web/create-page/create-page.component').then((m) => m.CreatePageComponent),
                                        // component: CreatePageComponent
                                    }
                                ]
                            },
                            {
                                path: 'navigation',
                                loadComponent: () => import('./components/web/navigation/navigation.component').then((m) => m.NavigationComponent),
                                // component: NavigationComponent,
                                data: {
                                    header: 'Website'
                                },
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./components/web/navigation-menu/navigation-menu.component').then((m) => m.NavigationMenuComponent),
                                        // component: NavigationMenuComponent
                                    },
                                    {
                                        path: 'navigation-menu',
                                        loadComponent: () => import('./components/web/navigation-menu/navigation-menu.component').then((m) => m.NavigationMenuComponent),
                                        // component: NavigationMenuComponent
                                    },
                                    {
                                        path: 'add-menu',
                                        loadComponent: () => import('./components/web/add-menu/add-menu.component').then((m) => m.AddMenuComponent),
                                        // component: AddMenuComponent
                                    },
                                    {
                                        path: 'edit-menu',
                                        loadComponent: () => import('./components/web/edit-menu/edit-menu.component').then((m) => m.EditMenuComponent),
                                        // component: EditMenuComponent
                                    }
                                ]
                            }
                        ]
                    }
                ]
                // loadChildren: () =>
                //   import("./modules/web/web.module").then((m) => m.WebModule),
            },
            {
                path: "channel-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'myChannel',
                        pathMatch: 'full'
                    },
                    {
                        path: '',
                        redirectTo: 'contacts',
                        pathMatch: 'full'
                    },
                    {
                        path: 'myChannel',
                        loadComponent: () => import('./components/channel/channel.component').then((m) => m.ChannelComponent),
                        // component: ChannelComponent,
                        data: {
                            header: 'My Channel'
                        },
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('./components/channel/channel-list/channel-list.component').then((m) => m.ChannelListComponent),
                                // component: ChannelListComponent,
                                resolve: { data: ChannelResolver },
                                data: {
                                    header: 'My Channel',
                                },
                            },
                            {
                                path: 'add-channel',
                                loadComponent: () => import('./components/channel/add-channel/add-channel.component').then((m) => m.AddChannelComponent),
                                // component: AddChannelComponent,
                                data: {
                                    header: 'My Channel',
                                },
                            },
                            {
                                path: 'add-channel/:id',
                                loadComponent: () => import('./components/channel/add-channel/add-channel.component').then((m) => m.AddChannelComponent),
                                // component: AddChannelComponent,
                                data: {
                                    header: 'My Channel',
                                },
                            },
                            {
                                path: 'networks',
                                loadComponent: () => import('./components/channel/channel-series/channel-series.component').then((m) => m.ChannelSeriesComponent),
                                // component: ChannelSeriesComponent,
                                data: {
                                    header: 'My Channel',
                                },
                            },
                            {
                                path: 'networks/:id',
                                loadComponent: () => import('./components/channel/channel-series/channel-series.component').then((m) => m.ChannelSeriesComponent),
                                // component: ChannelSeriesComponent,
                                data: {
                                    header: 'My Channel',
                                },
                            },
                        ]
                    }
                ]
                // loadChildren: () =>
                //   import("./modules/channel/channel.module").then(
                //     (m) => m.ChannelModule
                //   ),
            },
            {
                path: "payment-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'payment',
                        pathMatch: 'full'
                    },
                    {
                        path: 'payment',
                        loadComponent: () => import('./components/payment/payment.component').then((m) => m.PaymentComponent),
                        // component : PaymentComponent,
                        data: {
                            header: 'Payment'
                        }
                    }
                ]
                // loadChildren: () =>
                //   import("./modules/payment/payment.module").then(
                //     (m) => m.PaymentModule
                //   ),
            },
            {
                path: "profile",
                children: [
                    {
                        path: '',
                        redirectTo: 'profile',
                        pathMatch: 'full'
                    },
                    {
                        path: 'profile',
                        loadComponent: () => import('./components/profile/profile.component').then((m) => m.ProfileComponent),
                        // component: ProfileComponent,
                        data: {
                            header: 'Profile'
                        },
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('./components/profile/my-profile/my-profile.component').then((m) => m.MyProfileComponent),
                                // component: MyProfileComponent,
                                data: {
                                    header: 'My Profile'
                                }
                            },
                            {
                                path: 'my-profile',
                                loadComponent: () => import('./components/profile/my-profile/my-profile.component').then((m) => m.MyProfileComponent),
                                // component: MyProfileComponent,
                                data: {
                                    header: 'My Profile'
                                }
                            }
                        ]
                    }
                ]
                // loadChildren: () =>
                //   import("./modules/profile/profile.module").then(
                //     (m) => m.ProfileModule
                //   ),
            },
            {
                path: "AI",
                children: [
                    {
                        path: '',
                        redirectTo: 'chat-support',
                        pathMatch: "full"
                    },
                    {
                        path: 'chat-support',
                        loadComponent: () => import('./components/chat-support/chat-support.component').then((m) => m.ChatSupportComponent),
                        // component : ChatSupportComponent,
                        data: {
                            header: 'AI Support'
                        },
                        children: [
                            {
                                path: 'chp',
                                loadComponent: () => import('./components/chat-support/chat-history/chat-history.component').then((m) => m.ChatHistoryComponent),
                                //  component: ChatHistoryComponent,
                                data: {
                                    header: 'AI Support',
                                },
                            },
                            {
                                path: 'training-data',
                                loadComponent: () => import('./components/chat-support/training-data/training-data.component').then((m) => m.TrainingDataComponent),
                                //  component : TrainingDataComponent,
                                data: {
                                    header: 'AI Support',
                                },
                            },
                            {
                                path: 'add-training-data',
                                loadComponent: () => import('./components/chat-support/add-training-data/add-training-data.component').then((m) => m.AddTrainingDataComponent),
                                // component: AddTrainingDataComponent,
                                data: {
                                    header: 'AI Support',
                                },
                            },
                            {
                                path: 'view-chat-history/:id',
                                loadComponent: () => import('./components/chat-support/view-chat-history/view-chat-history.component').then((m) => m.ViewChatHistoryComponent),
                                // component:ViewChatHistoryComponent, 
                                data: {
                                    header: 'AI Support',
                                },
                            },
                            {
                                path: 'unTrained-data',
                                loadComponent: () => import('./components/chat-support/untrained-data/untrained-data.component').then((m) => m.UntrainedDataComponent),
                                // component:UntrainedDataComponent, 
                                data: {
                                    header: 'AI Support',
                                },
                            },
                            {
                                path: 'add-untrained-data',
                                loadComponent: () => import('./components/chat-support/add-untrained-data/add-untrained-data.component').then((m) => m.AddUntrainedDataComponent),
                                // component:AddUntrainedDataComponent,
                                data: {
                                    header: 'AI Support',
                                },
                            }

                        ]
                    }
                ]
                // loadChildren: () =>
                //   import("./modules/chat-support/chat-support.module").then(
                //     (m) => m.ChatSupportModule
                //   ),
            },
            {
                path: "data-usage-module",
                children: [
                    {
                        path: '',
                        redirectTo: 'myDataUsage',
                        pathMatch: 'full'
                    },
                    {
                        path: 'myDataUsage',
                        loadComponent: () => import('./components/data-usage/data-usage.component').then((m) => m.DataUsageComponent),
                        // component: DataUsageComponent,
                        data: {
                            header: 'Data Usage'
                        },
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('./components/data-usage/data-usage-list/data-usage-list.component').then((m) => m.DataUsageListComponent),
                                // component: DataUsageListComponent,
                                // resolve: { data: ChannelResolver },
                                data: {
                                    header: 'Data Usage',
                                },
                            },
                            {
                                path: 'dataUsageList',
                                loadComponent: () => import('./components/data-usage/data-usage-list/data-usage-list.component').then((m) => m.DataUsageListComponent),
                                // component: DataUsageListComponent,
                                // resolve: { data: ChannelResolver },
                                data: {
                                    header: 'Data Usage',
                                },
                            },
                            {
                                path: 'costCalculator',
                                loadComponent: () => import('./components/data-usage/cost-calculator/cost-calculator.component').then((m) => m.CostCalculatorComponent),
                                // component: CostCalculatorComponent,
                                // resolve: { data: ChannelResolver },
                                data: {
                                    header: 'Data Usage',
                                },
                                children: [
                                    {
                                        path: '',
                                        redirectTo: 'storageCosting',
                                        pathMatch: 'full'
                                    },
                                    {
                                        path: 'storageCosting',
                                        loadComponent: () => import('./components/data-usage/cost-calculator/storage-costing/storage-costing.component').then((m) => m.StorageCostingComponent),
                                        // component: StorageCostingComponent,
                                        // resolve: { data: RevenueResolver },
                                        data: {
                                            header: 'Data Usage',
                                        }
                                    },
                                    {
                                        path: 'VodDataTransferCosting',
                                        loadComponent: () => import('./components/data-usage/cost-calculator/vod-data-transfer-costing/vod-data-transfer-costing.component').then((m) => m.VodDataTransferCostingComponent),
                                        // component: VodDataTransferCostingComponent,
                                        // resolve: { data: ExpenseResolver },
                                        data: {
                                            header: 'Data Usage',
                                        },
                                    },
                                    {
                                        path: 'edit-storage-costing',
                                        loadComponent: () => import('./components/data-usage/cost-calculator/add-storage-costing/add-storage-costing.component').then((m) => m.AddStorageCostingComponent),
                                        // component: AddStorageCostingComponent,
                                        data: {
                                            header: 'Data Usage',
                                        }
                                    },
                                    {
                                        path: 'edit-storage-costing/:id',
                                        loadComponent: () => import('./components/data-usage/cost-calculator/add-storage-costing/add-storage-costing.component').then((m) => m.AddStorageCostingComponent),
                                        // component: AddStorageCostingComponent,
                                        data: {
                                            header: 'Data Usage',
                                        }
                                    },

                                    {
                                        path: 'add-vod-transfer-costing',
                                        loadComponent: () => import('./components/data-usage/cost-calculator/add-vod-transfer-costing/add-vod-transfer-costing.component').then((m) => m.AddVodTransferCostingComponent),
                                        // component: AddVodTransferCostingComponent,
                                        data: {
                                            header: 'Data Usage',
                                        }
                                    },
                                    {
                                        path: 'add-vod-transfer-costing/:id',
                                        loadComponent: () => import('./components/data-usage/cost-calculator/add-vod-transfer-costing/add-vod-transfer-costing.component').then((m) => m.AddVodTransferCostingComponent),
                                        // component: AddVodTransferCostingComponent,
                                        data: {
                                            header: 'Data Usage',
                                        }
                                    },

                                    {
                                        path: 'liveStreamUsage',
                                        loadComponent: () => import('./components/data-usage/cost-calculator/live-stream-usage/live-stream-usage.component').then((m) => m.LiveStreamUsageComponent),
                                        // component: LiveStreamUsageComponent,
                                        data: {
                                            header: 'Live Stream Data Usage',
                                        },
                                    },
                                    {
                                        path: 'add-live-stream',
                                        loadComponent: () => import('./components/data-usage/cost-calculator/add-live-stream/add-live-stream.component').then((m) => m.AddLiveStreamComponent),
                                        // component: AddLiveStreamComponent,
                                        data: {
                                            header: 'Live Data Usage',
                                        }
                                    },
                                    {
                                        path: 'add-live-stream/:id',
                                        loadComponent: () => import('./components/data-usage/cost-calculator/add-live-stream/add-live-stream.component').then((m) => m.AddLiveStreamComponent),
                                        // component: AddLiveStreamComponent,
                                        data: {
                                            header: 'Live Data Usage',
                                        }
                                    },
                                ]
                            },
                        ]
                    },
                ]
                // loadChildren: () =>
                //   import("./modules/data-usage/data-usage.module").then(
                //     (m) => m.DataUsageModule
                //   ),
            },
        ],
    },
]