import { Injectable } from '@angular/core';
import { appConfig } from 'src/app/app.config';
import { ResourceService } from '../resource/resource.service';

@Injectable({
  providedIn: 'root'
})
export class WebServiceService {

  constructor(
    public resourceService : ResourceService
  ) { }

  createWeb(data){
    const createWebURL = `${appConfig.pageCustomization}`;
    return this.resourceService.postData(createWebURL,data)
  }

  getWeb(){
    const getWebURL = `${appConfig.pageCustomization}/pageCustomizationId`;
    return this.resourceService.getData(getWebURL)
  }

  updateWeb(id,data){
    const updateWebURL = `${appConfig.pageCustomization}/${id}`;
    return this.resourceService.putData(updateWebURL,data)
  }

  creteCustomizeHeader(data){
    const createCustomizeHeaderURL = `${appConfig.header}`;
    return this.resourceService.postData(createCustomizeHeaderURL,data)
  }

  getCustomizeHeader(){
    const getCustomizeHeaderURL = `${appConfig.header}/pageCustomizationId`;
    return this.resourceService.getData(getCustomizeHeaderURL)
  }

  updateCustomizeHeader(id,data){
    const updateCustomizeHeaderURL = `${appConfig.header}/${id}`;
    return this.resourceService.putData(updateCustomizeHeaderURL,data)
  }

  createCustomizeFooter(data){
    const createCustomizeFooterURL = `${appConfig.footer}`;
    return this.resourceService.postData(createCustomizeFooterURL,data)
  }

  getCustomizeFooter(){
    const getCustomizeFooterURL = `${appConfig.footer}/footerId`;
    return this.resourceService.getData(getCustomizeFooterURL)
  }

  updateCustomizeFooter(id,data){
    const updateCustomizeFooterURL = `${appConfig.footer}/${id}`;
    return this.resourceService.putData(updateCustomizeFooterURL,data)
  }

  createCustomizeMainBanner(data){
    const createCustomizeMainBannerURL = `${appConfig.mainBanner}`;
    return this.resourceService.postData(createCustomizeMainBannerURL,data)
  }

  getCustomizeMainBanner(){
    const getCustomizeMainBannerURL = `${appConfig.mainBanner}/mainBannerId`;
    return this.resourceService.getData(getCustomizeMainBannerURL)
  }

  updateCustomizeMainBanner(id,data){
    const updateCustomizeMainBannerURL = `${appConfig.mainBanner}/${id}`;
    return this.resourceService.putData(updateCustomizeMainBannerURL,data)
  }

  createCustomizeBootomCallToAction(data){
    const createCustomizeBootomCallToActionURL = `${appConfig.bottomCallAction}`;
    return this.resourceService.postData(createCustomizeBootomCallToActionURL,data)
  }

  getCustomizeBootomCallToAction(){
    const getCustomizeBootomCallToActionURL = `${appConfig.bottomCallAction}/bottomCallActionId`;
    return this.resourceService.getData(getCustomizeBootomCallToActionURL)
  }

  updateCustomizeBootomCallToAction(id,data){
    const updateCustomizeBootomCallToActionURL = `${appConfig.bottomCallAction}/${id}`;
    return this.resourceService.putData(updateCustomizeBootomCallToActionURL,data)
  }

  createCustomizeBrowsePrograms(data){
    const createCustomizeBrowseProgramsURL = `${appConfig.browsePrograms}`;
    return this.resourceService.postData(createCustomizeBrowseProgramsURL,data)
  }

  getCustomizeBrowsePrograms(){
    const getCustomizeBrowseProgramsURL = `${appConfig.browsePrograms}/browseProgramsId`;
    return this.resourceService.getData(getCustomizeBrowseProgramsURL)
  }

  updateCustomizeBrowsePrograms(id,data){
    const updateCustomizeBrowseProgramsURL = `${appConfig.browsePrograms}/${id}`;
    return this.resourceService.putData(updateCustomizeBrowseProgramsURL,data)
  }

  getFreeMediaItems(page,size){
    const getFreeMediaItemsURL = `${appConfig.mediaItem}/getAllMediaItemList?mediaAccessType=FREE&page=${page}&size=${size}`;
    // const getFreeMediaItemsURL = `${appConfig.displayMediaItemInfo}/displayMediaItemInfoWithLogin?page=${page}&size=${size}`;
    return this.resourceService.getData(getFreeMediaItemsURL)
  }

  createCustomizeAllPlatforms(data){
    const createCustomizeAllPlatformsURL = `${appConfig.allPlatForms}`;
    return this.resourceService.postData(createCustomizeAllPlatformsURL,data)
  }

  getCustomizeAllPlatforms(){
    const getCustomizeAllPlatformsURL = `${appConfig.allPlatForms}/allPlatformsId`;
    return this.resourceService.getData(getCustomizeAllPlatformsURL)
  }

  updateCustomizeAllPlatforms(id,data){
    const updateCustomizeAllPlatformsURL = `${appConfig.allPlatForms}/${id}`;
    return this.resourceService.putData(updateCustomizeAllPlatformsURL,data)
  }  

  createCustomizeWebSettings(data){
    const createCustomizeWebSettingsURL = `${appConfig.webAppSetting}`;
    return this.resourceService.postData(createCustomizeWebSettingsURL,data)
  }

  getCustomizeWebSettings(){
    const getCustomizeWebSettingsURL = `${appConfig.webAppSetting}/webAppSettingId`;
    return this.resourceService.getData(getCustomizeWebSettingsURL)
  }

  updateCustomizeWebSettings(id,data){
    const updateCustomizeWebSettingsURL = `${appConfig.webAppSetting}/${id}`;
    return this.resourceService.putData(updateCustomizeWebSettingsURL,data)
  }  

  saveCustomDomain(domain){
    const saveCustomDomain = `${appConfig.customDomainURL}?domain=${domain}`;
    return this.resourceService.postData(saveCustomDomain)
  }

  saveCustomDomainWithWWW(domain){
    const saveCustomDomain = `${appConfig.customDomainWWWURL}?domain=${domain}`;
    return this.resourceService.postData(saveCustomDomain)
  }

}
