import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(
        private router: Router
    ) { }

    canActivate(): boolean {
        if (localStorage.getItem('loggedInUser')) {
            return true;
        } else {
            this.router.navigate(['login']);
            return false;
        }
        // const token = localStorage.getItem('loggedInUser');
        // if (!token) {
        //   this.router.navigate(['login']);
        //   return false;
        // }
        // return true;
    }

}
