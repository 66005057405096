import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticateService } from 'src/app/services/authenticateService/authenticate.service';

@Injectable({
  providedIn: 'root',
})
export class PlatformResolver{
  constructor(private authService: AuthenticateService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const orgId = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails.organization.id
      : "";

    return forkJoin({
      activeTabs: this.authService.getTabsWithoutAuth(orgId).pipe(
        catchError(() => of([]))
      ),
      brandingData: this.authService.getBrandingDataWithoutAuth(orgId).pipe(
        catchError(() => of({}))
      )
    });
  }
}
