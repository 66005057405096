import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { RequestService } from "src/app/services/request/request.service";

@Injectable(
    {
        providedIn: 'root'
    }
)
export class RequestsResolver {
    constructor(public requestService: RequestService) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return this.requestService.getRequestcustomers(1, 10, '', '').toPromise().then(data => data.data)
    }
}