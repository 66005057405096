import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { MediaService } from "src/app/services/media/media.service";

@Injectable({
    providedIn: 'root'
})
export class ChannelResolver {
    constructor(public mediaService: MediaService) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return this.mediaService.getMediaItems(1, 5, '', 'CHANNEL', '').toPromise().then(data => data.data)
    }
}
