import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AppsService } from "src/app/services/apps/apps-service";
import { AuthenticateService } from "src/app/services/authenticateService/authenticate.service";

@Injectable({
    providedIn: 'root'
})
export class PushNotificationResolver {
    constructor(
        public appsService: AppsService,
    ) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const orgId = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.organization.id : '';

        return Promise.all([
            this.appsService.getNotificationList(orgId).toPromise().then(data => data),
            this.appsService.getArchiveGroup(orgId).toPromise().then(data => data),
            this.appsService.getDraftNotificationInfoList(1, 5, '').toPromise().then(data => data),
            this.appsService.getHistoryNotificationInfoList(1, 5, '').toPromise().then(data => data),
            this.appsService.getScheduledNotificationInfoList(1, 5, '').toPromise().then(data => data)
        ]);
    }
}