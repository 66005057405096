import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { LibraryServiceService } from "src/app/services/library/library-service.service";
import { MediaService } from "src/app/services/media/media.service";

@Injectable(
    {
        providedIn: 'root'
    }
)
export class MusicResolver {
    constructor(
        public mediaService: MediaService,
        public libraryService: LibraryServiceService
    ) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return Promise.all([
            this.mediaService.getSongList(1, 5, '').toPromise().then(data => data),
            this.mediaService.getAlbumList(1, 5, '').toPromise().then(data => data),
            this.libraryService.getSmartList(1, 10, '', true).toPromise().then(data => data),
        ]);
    }
}