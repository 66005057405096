import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AccountingService } from "src/app/services/accounting/accounting.service";

@Injectable(
    {
        providedIn: 'root',
    }
)
export class RevenueResolver {
    constructor(public accountingService: AccountingService) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return this.accountingService.getRevenue(1, 10, '', '', '', '', '').toPromise().then(data => data.data)
    }
}