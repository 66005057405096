import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { EventService } from "src/app/services/events/event-services";

@Injectable(
    {
        providedIn: 'root'
    }
)
export class EventListResolver {
    constructor(
        public eventService: EventService,
    ) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return Promise.all([
            this.eventService.getCalenderData(1, 4, '').toPromise().then(data => data),
            this.eventService.getCalenderData('', '', '').toPromise().then(data => data),
            this.eventService.getallEventData(1, 5, '').toPromise().then(data => data),
        ]);
    }
}