import { error } from 'protractor';
import { MessageService } from 'primeng/api';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthenticateService } from "src/app/services/authenticateService/authenticate.service";
import { SettingsServiceService } from "src/app/services/settings/settings-service.service";
import { SharedService } from 'src/app/services/shared/shared.service';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionResolver {
    constructor(
        public settingsService: SettingsServiceService,
        public authenticateService: AuthenticateService,
        public messageService: MessageService,
        public sharedService: SharedService
    ) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return Promise.all([
            this.settingsService.getCardDetails('').toPromise().then(data => data),
            this.settingsService.getSecretKey().toPromise().then(data => data),
            this.authenticateService.getCountryList().toPromise().then(data => data).catch((error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.error.message,
                });
                this.sharedService.updateLoader(false);
            })
        ]);
    }
}