import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { AuthenticateService } from 'src/app/services/authenticateService/authenticate.service';

@Injectable({
  providedIn: 'root'
})
export class SignupResolver{
  constructor(private authenticateService: AuthenticateService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const uuid = route.paramMap.get('uuid')

    return forkJoin({
      logoData: this.authenticateService.getLogo(uuid),
      countryData: this.authenticateService.getCountryList()
    });
  }
}
