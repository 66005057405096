import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { SettingsServiceService } from "src/app/services/settings/settings-service.service";

@Injectable({
    providedIn: 'root'
})
export class StripSettingResolver {
    constructor(public settingsService: SettingsServiceService) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return this.settingsService.getStripeCredentials().toPromise().then(data => data.data)
    }
}