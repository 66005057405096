import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { DashboardService } from "src/app/services/dashboard/dashboard.service";

@Injectable()
export class DashboardResolver {
    constructor(public _dashboardService: DashboardService) {

    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return Promise.all([
            // this._dashboardService.getPromotionalData().toPromise().then(data => data)
        ]);

    }
}