import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { catchError, EMPTY, map, Observable } from 'rxjs';
import { AuthenticateService } from 'src/app/services/authenticateService/authenticate.service';

@Injectable({
  providedIn: 'root'
})
export class TvAppResolver implements Resolve<any> {
  constructor(private authenticateService: AuthenticateService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const loggedInUser = localStorage.getItem('loggedInUser');
    const orgId = loggedInUser ? JSON.parse(loggedInUser).userDetails.organization.id : '';

    // return this.authenticateService.getTvAppSelectedType(orgId);
    return this.authenticateService.getTvAppSelectedType(orgId).pipe(
      map((response) => ({
        orgId,
        response,
      })),
      catchError(() => EMPTY)
    );
  }
}

