import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { config } from './app/config';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app/app-routing.module';
import { AuthenticateModule } from './app/modules/authenticate/authenticate.module';
import { CustomerModule } from './app/modules/customer/customer.module';
import { SharedModule } from './app/modules/shared/shared.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastModule } from 'primeng/toast';
import { provideRouter, RouterModule, RouterOutlet } from '@angular/router';
import { SubscriptionDetailResolver } from './app/components/subscription/subscription-detail/subscription-detail.resolver';
import { DashboardResolver } from './app/components/dashboard/dashboard.service';
import { EncrDecrService } from './app/services/shared/encrDecr.service';
import { AuthGuard } from './app/auth-guards/auth.guard';
import { AuthenticateRoutes } from './app/modules/authenticate/authenticate.route';
import { MessageService } from 'primeng/api';
import { routes } from './app/app-routes';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { NgxStripeModule } from 'ngx-stripe';
import { provideNgxStripe } from 'ngx-stripe';

if (environment.production) {
  enableProdMode();
}

// bootstrapApplication(AppComponent,config)
//   .catch(err => console.error(err));

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(MatDatepickerModule,RouterModule, ToastModule, CommonModule, FormsModule, RouterOutlet, BrowserModule, SharedModule, NgbModule,
        // NgxScrollPositionRestorationModule.forRoot(),
        NgChartsModule, BsDropdownModule.forRoot()),
        MessageService,
        AuthGuard,
        EncrDecrService,
        DashboardResolver,
        // SubscriptionDetailResolver,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideNgxStripe(),
        provideRouter(routes),
    ]
})
  .catch(err => console.error(err));