import { appConfig } from "./../../app.config";
import { EventEmitter, Injectable } from "@angular/core";
import { ResourceService } from "../resource/resource.service";
import {
    forkJoin,
    Subject,
    throwError as observableThrowError,
    BehaviorSubject,
} from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { stat } from "fs";

@Injectable({
    providedIn: "root",
})
export class CustomerService {
    dataUpdated: EventEmitter<any> = new EventEmitter<any>();
    constructor(public resourceService: ResourceService) { }

    getBaseData() {
        const getOnBoardingUser = this.resourceService.getData(
            `${appConfig.updateUser}/onboardingUser`
        );
        const getCountryList = this.resourceService.getData(appConfig.countryList);
        return forkJoin([getOnBoardingUser, getCountryList]).pipe(
            catchError(this.errorHandler)
        );
    }

    updateCustomer(formData, customerId) {
        const updateCustomerUrl = customerId
            ? `${appConfig.updateOrganization}/${customerId}`
            : appConfig.updateOrganization;
        if (customerId) {
            return this.resourceService.putData(updateCustomerUrl, formData);
        } else {
            return this.resourceService.postData(updateCustomerUrl, formData);
        }
    }

    getRokuAppInfoForCustomer(id) {
        const getRokuAppInfoForCustomerUrl = `${appConfig.appStoreInfo}/rokuAppInfo/${id}`;
        return this.resourceService.getData(getRokuAppInfoForCustomerUrl);
    }

    updateRokuAppInfo(formData, customerId) {
        const updateRokuAppInfoForCustomerUrl = `${appConfig.appStoreInfo}/rokuAppInfo/${customerId}`;
        return this.resourceService.putData(
            updateRokuAppInfoForCustomerUrl,
            formData
        );
        // else {
        //   return this.resourceService.postData(updateCustomerUrl, formData);
        // }
    }

    getCustomers(
        page,
        size,
        state,
        subscription,
        isActive,
        paymentStatus,
        search
    ) {
        const filter =
            isActive && paymentStatus && state && subscription && search
                ? `?isActive=${isActive}&page=${page}&paymentStatus=${paymentStatus}&searchkey=${search}&size=${size}&state=${state}&subscription=${subscription}`
                : isActive && paymentStatus && state && subscription
                    ? `?isActive=${isActive}&page=${page}&paymentStatus=${paymentStatus}&size=${size}&state=${state}&subscription=${subscription}`
                    : isActive && paymentStatus && subscription && search
                        ? `?isActive=${isActive}&page=${page}&paymentStatus=${paymentStatus}&searchkey=${search}&size=${size}&subscription=${subscription}`
                        : isActive && state && subscription && search
                            ? `?isActive=${isActive}&page=${page}&searchKey=${search}&size=${size}&state=${state}&subscription=${subscription}`
                            : // 3 filter selected
                            isActive && paymentStatus && subscription
                                ? `?isActive=${isActive}&page=${page}&paymentStatus=${paymentStatus}&size=${size}&subscription=${subscription}`
                                : (isActive && state && subscription) ||
                                    (!isActive && state && subscription)
                                    ? `?isActive=${isActive}&page=${page}&size=${size}&state=${state}&subscription=${subscription}`
                                    : isActive && paymentStatus && state
                                        ? `?isActive=${isActive}&page=${page}&paymentStatus=${paymentStatus}&size=${size}&state=${state}`
                                        : (isActive && subscription && search) ||
                                            (!isActive && subscription && search)
                                            ? `?isActive=${isActive}&page=${page}&searchkey=${search}&size=${size}&subscription=${subscription}`
                                            : isActive && paymentStatus && search
                                                ? `?isActive=${isActive}&page=${page}&paymentStatus=${paymentStatus}&searchkey=${search}&size=${size}`
                                                : // 2 filters selected

                                                //  ((state && subscription) ? `&state=${state}&subscription=${subscription}` :
                                                //  ((state && search) ? `?isActive=${isActive}&page=${page}&searchkey=${search}&size=${size}&state=${state}` :
                                                //  ((paymentStatus && search) ? `&paymentStatus=${paymentStatus}&searchkey=${search}` :
                                                //  ((search && paymentStatus) ? `&searchkey=${search}&subscription=${subscription}` :
                                                isActive && paymentStatus
                                                    ? `?isActive=${isActive}&page=${page}&paymentStatus=${paymentStatus}`
                                                    : (isActive && subscription) || (!isActive && subscription)
                                                        ? `?isActive=${isActive}&subscription=${subscription}`
                                                        : isActive && state
                                                            ? `?isActive=${isActive}&page=${page}&size=${size}&state=${state}`
                                                            : isActive && search
                                                                ? `?isActive=${isActive}&page=${page}&searchkey=${search}`
                                                                : // 1 filter selected
                                                                state
                                                                    ? `&state=${state}`
                                                                    : subscription
                                                                        ? `&subscription=${subscription}`
                                                                        : search
                                                                            ? `&searchkey=${search}`
                                                                            : isActive || !isActive
                                                                                ? `?isActive=${isActive}`
                                                                                : paymentStatus
                                                                                    ? `&paymentStatus=${paymentStatus}`
                                                                                    : "";
        if (
            (isActive && paymentStatus && state && subscription && search) ||
            (isActive && state && subscription && search) ||
            (!isActive && state && subscription && search) ||
            (isActive && paymentStatus && subscription && search) ||
            (isActive && state) ||
            (isActive && paymentStatus && subscription) ||
            (isActive && paymentStatus && state && subscription) ||
            (isActive && subscription && search) ||
            (!isActive && subscription && search) ||
            (isActive && paymentStatus && search) ||
            (isActive && state && subscription) ||
            (isActive && paymentStatus && state)
        ) {
            const getCustomerUrl = `${appConfig.updateOrganization}${filter}`;
            return this.resourceService.getData(getCustomerUrl);
        } else if ((isActive && paymentStatus) || (isActive && search)) {
            const getCustomerUrl = `${appConfig.updateOrganization}${filter}&size=${size}`;
            return this.resourceService.getData(getCustomerUrl);
        }

        // else if ((paymentStatus && subscription) || (paymentStatus && subscription && state)) {
        //   const getCustomerUrl = `${appConfig.updateOrganization}?page=${page}${filter}`;
        //   return this.resourceService.getData(getCustomerUrl);
        // }
        else if (
            (!isActive && subscription) ||
            !isActive ||
            (isActive && subscription)
        ) {
            const getCustomerUrl = `${appConfig.updateOrganization}${filter}&page=${page}&size=${size}`;
            return this.resourceService.getData(getCustomerUrl);
        } else {
            const getCustomerUrl = `${appConfig.updateOrganization}${filter}&page=${page}&size=${size}`;
            return this.resourceService.getData(getCustomerUrl);
        }
    }

    getCustomerById(customerId) {
        const getCustomerByIdUrl = `${appConfig.updateOrganization}/${customerId}`;
        return this.resourceService.getData(getCustomerByIdUrl);
    }

    deleteCustomer(customerId) {
        const deleteCustomerUrl = `${appConfig.updateOrganization}/${customerId}`;
        return this.resourceService.deleteData(deleteCustomerUrl);
    }

    sendPasswordLinkCustomer(basicInfoId) {
        const deleteCustomerUrl = `${appConfig.user}/sendForgetEmail/${basicInfoId}`;
        return this.resourceService.postData(deleteCustomerUrl);
    }
    sendEmailVerificationLinkCustomer(customerId) {
        const sendEmailVerificationLink = `${appConfig.organization}/sendVerificationMail?organizationId=${customerId}`;
        return this.resourceService.getData(sendEmailVerificationLink);
    }
    sendGettingStartedMail(customerId) {
        const deleteCustomerUrl = `${appConfig.updateOrganization}/sendGettingStartedMail?organizationId=${customerId}`;
        return this.resourceService.getData(deleteCustomerUrl);
    }

    changeCustomerPassword(basicInfoId, userEmail) {
        const deleteCustomerUrl = `${appConfig.user}/resetUserPassword/${basicInfoId}`;
        return this.resourceService.postData(deleteCustomerUrl, userEmail);
    }

    editEmailCustomer(basicInfoId, userEmail) {
        const editEmailCustomerUrl = `${appConfig.user}/updateUserNameOrEmail/${basicInfoId}`;
        return this.resourceService.putData(editEmailCustomerUrl, userEmail);
    }

    switchUser(data) {
        const switchUserUrl = `${appConfig.switch}`;
        return this.resourceService.putData(switchUserUrl, data);
    }

    updateStatus(id, status) {
        const updateStatusUrl = `${appConfig.updateOrganization}/status/${id}?status=${status}`;
        return this.resourceService.putData(updateStatusUrl, {});
    }

    getCountryList() {
        const getCountryList = appConfig.countryList;
        return this.resourceService.getData(getCountryList);
    }

    uploadImage(formData) {
        const uploadUrl = `${appConfig.upload}/image`;
        return this.resourceService.postData(uploadUrl, formData);
    }

    uploadBase64(stockData) {
        const uploadBaseUrl = `${appConfig.upload}/base64image`;
        return this.resourceService.postData(uploadBaseUrl, stockData);
    }

    createTemplate(templateData, templateType) {
        const templateUrl = `${appConfig.template}?templateType=${templateType}`;
        return this.resourceService.postData(templateUrl, templateData);
    }

    updateEmailTemplate(templateData, id) {
        const templateUrl = `${appConfig.template}/${id}`;
        return this.resourceService.putData(templateUrl, templateData);
    }

    updateEmailTemplateInOrganization(templateData, id, templateType) {
        const templateUrl = `${appConfig.content}/updateTemplate/${id}?templateType=${templateType}`;
        return this.resourceService.putData(templateUrl, templateData);
    }

    updateSelectedTemplate(id) {
        const templateUrl = `${appConfig.template}/update/${id}`;
        return this.resourceService.putData(templateUrl);
    }

    getTemplate() {
        const getTemplateUrl = appConfig.template;
        return this.resourceService.getData(getTemplateUrl);
    }

    deleteTemplate(templateId) {
        const deleteTemplateUrl = `${appConfig.template}/${templateId}`;
        return this.resourceService.deleteData(deleteTemplateUrl);
    }

    getContent(templateId) {
        const getContentUrl = `${appConfig.template}/templateWithoutOrganization/${templateId}?flag=true`;
        return this.resourceService.getData(getContentUrl);
    }

    geteditContent(templateId) {
        const getContentUrl = `${appConfig.template}/template/${templateId}`;
        return this.resourceService.getData(getContentUrl);
    }

    getEditContentInOrganization(id) {
        const getContentUrl = `${appConfig.content}/templateWithoutOrganization/${id}`;
        return this.resourceService.getData(getContentUrl);
    }

    sendMail(content) {
        const sendMailUrl = `${appConfig.template}/mail`;
        return this.resourceService.postData(sendMailUrl, content);
    }

    updateTemplate(templateId, data) {
        const updateTemplateUrl = `${appConfig.template}/${templateId}`;
        return this.resourceService.putData(updateTemplateUrl, data);
    }

    validateEmail(email) {
        const validateEmailUrl = `${appConfig.template}/validateEmail?emailIds=${email}`;
        return this.resourceService.getData(validateEmailUrl);
    }

    uploadPromotionalImage(id, formData) {
        const uploadPromotionalImageURL = `${appConfig.organization}/uploadPromotionalImage?organizationId=${id}`;
        return this.resourceService.postData(uploadPromotionalImageURL, formData);
    }

    errorHandler(error: HttpErrorResponse) {
        return observableThrowError(error || "Server Error");
    }

    getSampleTemplates() {
        const getSampleTemplatesURL = `${appConfig.content}/SampleTemplate`;
        return this.resourceService.getData(getSampleTemplatesURL);
    }

    updateMail = new Subject();
    updateMail$ = this.updateMail.asObservable();

    massMail(data) {
        this.updateMail.next(data);
    }

    getEmailTemplateByType(search, type) {
        const searching = search
            ? `?searchkey=${search}&templateType=${type}`
            : `?templateType=${type}`;
        // const getEmailTemplateByType = `${appConfig.content}/templateListByTemplateType?templateType=${type}`
        const getEmailTemplateByType = `${appConfig.content}/templateListByTemplateType${searching}`;
        return this.resourceService.getData(getEmailTemplateByType);
    }

    getTemplateForOthers(type) {
        const getEmailTemplateByType = `${appConfig.content}/OtherTemplate?templateType=${type}`;
        return this.resourceService.getData(getEmailTemplateByType);
    }

    imageCovertor(data) {
        const getImageConvertorType = `${appConfig.upload}/imageFromSingleUrlThenToBase64`;
        return this.resourceService.postData(getImageConvertorType, data);
    }

    getAllOrganizationList() {
        const getAllOrganization = `${appConfig.organization}/organizationListWithoutPagination`;
        return this.resourceService.getData(getAllOrganization);
    }

    getAllOrganizationListInSort() {
        const getAllOrganization = `${appConfig.organization}/getAllOrganizationList`;
        return this.resourceService.getData(getAllOrganization);
    }

    deleteDiscard(discardInvoiceID) {
        const deleteInvoiceUrl = `${appConfig.usersubScription}/?invoiceId=${discardInvoiceID}`;
        return this.resourceService.deleteData(deleteInvoiceUrl);
    }

    processPendingStatus(pendingInvoiceId) {
        const processInvoiceUrl = `${appConfig.usersubScription}/processInvoice?invoiceId=${pendingInvoiceId}`;
        return this.resourceService.postData(processInvoiceUrl);
    }

    invoiceStatus(invoiceId) {
        const processInvoiceUrl = `${appConfig.invoice}/${invoiceId}`;
        return this.resourceService.putData(processInvoiceUrl);
    }

    updateInvoiceData(data, invoiceId) {
        const processInvoiceUrl = `${appConfig.invoice}/${invoiceId}`;
        return this.resourceService.putData(processInvoiceUrl, data);
    }

    updateFeatureForOrg(data) {
        const updateFeatureForOrgUrl = `${appConfig.organization}/subscriptionPlanFeatures`;
        return this.resourceService.putData(updateFeatureForOrgUrl, data);
    }

    private showModalSource = new Subject<boolean>();
    showModal$ = this.showModalSource.asObservable();
    openModal(): void {
        this.showModalSource.next(true);
    }
    closeModal(): void {
        this.showModalSource.next(false);
    }
    sendData(data: any) {
        this.dataUpdated.emit(data);
    }
    saveCustomDomain(domain) {
        const saveCustomDomain = `${appConfig.customDomainURL}?domain=${domain}`;
        return this.resourceService.postData(saveCustomDomain);
    }

    updateOrgPrice(orgId, price, subscriptionPlanId) {
        const subscriptionPlanFeaturesURL = `${appConfig.organization}/subscriptionPlanFeatures/price?organizationId=${orgId}&price=${price}&subscriptionPlanId=${subscriptionPlanId}`;
        return this.resourceService.putData(subscriptionPlanFeaturesURL);
    }

    getDataUsageList(StartDate, page, size, searchKey) {
        const search = searchKey ? `&searchKey=${searchKey}` : "";
        const dataUsageListUrl = `${appConfig.memoryStorage}/dataTransferOfAllOrganizations?date=${StartDate}&page=${page}${search}&size=${size}`;
        return this.resourceService.getData(dataUsageListUrl);
    }
    private sharedDataSubject = new BehaviorSubject<any>(null);
    sharedData$ = this.sharedDataSubject.asObservable();

    updateSharedData(data: any) {
        this.sharedDataSubject.next(data);
    }
}
