import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { SubscriptionServiceService } from "src/app/services/subscription/subscription-service.service";

@Injectable({
    providedIn: 'root'
})
export class SubcribedUserResolver {
    loginUserDetail: any;
    orgId: any;
    constructor(
        public subscriptionService: SubscriptionServiceService,
    ) {}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        this.loginUserDetail = localStorage.getItem("loggedInUser");
        this.orgId = this.loginUserDetail
            ? JSON.parse(this.loginUserDetail).userDetails.organization.id
            : "";
        return Promise.all([
            this.subscriptionService.getSubscriptionList(this.orgId).toPromise().then(data => data),
        ]);
    }
}

