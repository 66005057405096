import { appConfig } from './../../app.config';
import { Injectable } from '@angular/core';
import { ResourceService } from '../resource/resource.service';

@Injectable({
  providedIn: 'root'
})

export class AccountingService {

  constructor(public resourceService: ResourceService) { }

  updateExpense(formData, expenseId, type) {
    const url = (type === 'expense') ? 'updateExpense' : 'updateRevenue';
    const updateExpenseUrl = expenseId ? `${appConfig[url]}/${expenseId}` : appConfig[url];
    if (expenseId) {
      return this.resourceService.putData(updateExpenseUrl, formData);
    } else {
      return this.resourceService.postData(updateExpenseUrl, formData);
    }
  }

  getExpenses(page, size, year, month, search?) {
    const filterUrl = (year && !month) ? `&year=${year}` : ((year && month) ? `&yearMonth=${year}-${month}` : '');
    const updatedFilterUrl = search ? `${filterUrl}&searchKey=${search}` : filterUrl;
    const getExpenseUrl = `${appConfig.getExpenseList}?page=${page}&size=${size}${updatedFilterUrl}`;
    return this.resourceService.getData(getExpenseUrl);
  }

  getDataById(id, type) {
    const updateExpenseUrl = (type === 'expense') ? `${appConfig.updateExpense}/${id}` : `${appConfig.updateRevenue}/${id}`;
    return this.resourceService.getData(updateExpenseUrl);
  }

  getInvoiceDataById(id) {
    const getInvoiceDataUrl = `${appConfig.user}/invoiceInfo/${id}`;
    return this.resourceService.getData(getInvoiceDataUrl);
  }

  getRevenue(page, size, year, month, search,endDate,startDate) {
    const filterUrl = (year && !month) ? `&year=${year}` : ((year && month) ? `&yearMonth=${year}-${month}` : '');
    // const updatedFilterUrl = search ? `${filterUrl}&searchKey=${search}` : filterUrl;
    const end = endDate ? `endDate=${endDate}&` : ''
    const filter = search ? `&searchKey=${search}` : ''
    const start = startDate ? `&startDate=${startDate}` : ''


    // const getRevenueUrl = `${appConfig.getRevenueList}?page=${page}&size=${size}${updatedFilterUrl}`;
    const getRevenueUrl = `${appConfig.getRevenueList}?${end}&page=${page}${filter}&size=${size}${filterUrl}${start}`;
    return this.resourceService.getData(getRevenueUrl);
  }

  deleteAccount(id, type) {
    const deleteUrl = type === 'expense' ? `${appConfig.updateExpense}/${id}` : `${appConfig.updateRevenue}/${id}`;
    return this.resourceService.deleteData(deleteUrl);
  }

}

