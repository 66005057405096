import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { LibraryServiceService } from "src/app/services/library/library-service.service";
import { MediaService } from "src/app/services/media/media.service";

@Injectable({
    providedIn: 'root'
})
export class EbookListResolver {
    constructor(
        public mediaService: MediaService,
        public libraryService: LibraryServiceService
    ) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return Promise.all([
            this.mediaService.getMediaItems(1, 5, '', 'EBOOK', '').toPromise().then(data => data),
            this.mediaService.getMediaSeries(1, 4, '', 'EBOOK_SERIES').toPromise().then(data => data),
        ]);
    }
}